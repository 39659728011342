import { isDate, isDateString, isObject } from 'class-validator';
import { ISettings, isFutureDate, objectFilter, sortBy } from './index';
import * as _ from 'lodash';

export enum AppointmentStatus {
  NoShow = 'NoShow',
  Cancelled = 'Cancelled',
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  Arrived = 'Arrived',
  InProgress = 'InProgress',
  Completed = 'Completed'
}

// "Record" in Typescript is a helper meta-type that creates
// a "table" type that maps one type to another!
// In this case StatusTimeline is the shape of an object whose keys are
// of type AppointmentStatus, and whose values are of type Date.
export type StatusTimeline = Record<AppointmentStatus, Date>;

export type ChangeRules = {
  isHappyPath: boolean;
  happyPathOrder: number;
  isStartState: boolean;
  isEndState: boolean;
  canUndo: boolean;
  isOptional?: boolean;
  allowUpdateToFutureDate?: boolean;
  validNextStatuses: AppointmentStatus[];
  undoStatus: AppointmentStatus;
};

export type StatusChangeRules = Record<AppointmentStatus, ChangeRules>;

export function getStatusChangeRules(
  optionalStatuses: AppointmentStatus[] = [
    AppointmentStatus.Requested,
    AppointmentStatus.InProgress
  ]
): StatusChangeRules {
  const statusRules = {
    [AppointmentStatus.Requested]: {
      isHappyPath: true,
      happyPathOrder: 0.5,
      isStartState: true,
      isEndState: false,
      canUndo: false,
      isOptional: true,
      validNextStatuses: [AppointmentStatus.Scheduled, AppointmentStatus.Cancelled],
      undoStatus: null,
      allowUpdateToFutureDate: true
    },
    [AppointmentStatus.Scheduled]: {
      isHappyPath: true,
      happyPathOrder: 1,
      isStartState: true,
      isEndState: false,
      canUndo: true,
      validNextStatuses: [
        AppointmentStatus.Arrived,
        AppointmentStatus.NoShow,
        AppointmentStatus.Cancelled
      ],
      undoStatus: AppointmentStatus.Requested,
      allowUpdateToFutureDate: true
    },
    [AppointmentStatus.Arrived]: {
      isHappyPath: true,
      happyPathOrder: 2,
      isStartState: false,
      isEndState: false,
      canUndo: true,
      validNextStatuses: [
        AppointmentStatus.InProgress,
        AppointmentStatus.Completed,
        AppointmentStatus.Cancelled
      ],
      undoStatus: AppointmentStatus.Scheduled,
      allowUpdateToFutureDate: true
    },
    [AppointmentStatus.InProgress]: {
      isHappyPath: true,
      happyPathOrder: 2.5,
      isStartState: false,
      isOptional: true,
      isEndState: false,
      canUndo: true,
      validNextStatuses: [AppointmentStatus.Completed],
      undoStatus: AppointmentStatus.Arrived,
      allowUpdateToFutureDate: false
    },
    [AppointmentStatus.Completed]: {
      isHappyPath: true,
      happyPathOrder: 3,
      isStartState: false,
      isEndState: true,
      canUndo: true,
      validNextStatuses: [],
      // WARNING: This is not exactly true! In practice Neutron
      // will choose either Arrived or InProgress dynamically,
      // based on the timeline. See "computeUndoStatus()".
      undoStatus: AppointmentStatus.Arrived,
      allowUpdateToFutureDate: true
    },
    [AppointmentStatus.NoShow]: {
      isHappyPath: false,
      happyPathOrder: 0,
      isStartState: false,
      isEndState: true,
      canUndo: true,
      validNextStatuses: [],
      undoStatus: AppointmentStatus.Scheduled,
      allowUpdateToFutureDate: true
    },
    [AppointmentStatus.Cancelled]: {
      isHappyPath: false,
      happyPathOrder: 0,
      isStartState: false,
      isEndState: true,
      canUndo: false,
      validNextStatuses: [],
      undoStatus: null,
      allowUpdateToFutureDate: true
    }
  };

  // Provide status change rules based on optional statuses given
  const rulesSortedByPathOrder = Object.keys(statusRules).sort((a, b) => {
    return statusRules[a].happyPathOrder - statusRules[b].happyPathOrder;
  });

  const nonHappyPathStatusCount = Object.values(statusRules).filter(
    statusRule => !statusRule.isHappyPath
  ).length;

  const happyPathStatusCount =
    rulesSortedByPathOrder.filter(status => {
      const statusRule = statusRules[status];
      return (
        !statusRule.isOptional ||
        (statusRule.isOptional && optionalStatuses.includes(status as AppointmentStatus))
      );
    }).length - nonHappyPathStatusCount;

  let stepCount = 1;
  let nonHappyPathIncrementor = happyPathStatusCount + 1;

  const adjustedRules = {} as StatusChangeRules;

  rulesSortedByPathOrder.forEach(status => {
    const statusRule = statusRules[status];
    if (statusRule.isOptional) {
      if (optionalStatuses.includes(status as AppointmentStatus)) {
        addStepNumber(stepCount, status, statusRule);
        stepCount++;
      }
    } else if (statusRule.isHappyPath) {
      addStepNumber(stepCount, status, statusRule);
      stepCount++;
    } else {
      addStepNumber(nonHappyPathIncrementor, status, statusRule);
      nonHappyPathIncrementor++;
    }
  });

  function addStepNumber(stepNumber, status, statusRule) {
    if (stepNumber === 1) {
      statusRule.canUndo = false;
    }
    statusRule.stepNumber = stepNumber;
    adjustedRules[status] = statusRule;
  }

  return adjustedRules;
}

export function getAllStatuses(): AppointmentStatus[] {
  return Object.keys(AppointmentStatus) as AppointmentStatus[];
}

export function getNonOptionalStatuses(): AppointmentStatus[] {
  const all = getAllStatuses();
  const optionals = getOptionalStatuses();

  return all.filter(status => !optionals.includes(status as AppointmentStatus));
}

export function getOptionalStatuses(): AppointmentStatus[] {
  const statusRules = getStatusChangeRules();
  const search = objectFilter(statusRules, key => statusRules[key].isOptional);

  return Object.keys(search) as AppointmentStatus[];
}

/*
 * Returns true when a status timeline date is trying to be set
 * and the current status does not allow this date to be in the
 * future. Check allowUpdateToFutureDate property on the
 * getStatusChangeRules() settings for more details.
 * In general it always should return false, representing a
 * valid timeline change.
 */
export function isTimelineStatusChangeDateInvalid(
  status: AppointmentStatus,
  timeStamp: string
): boolean {
  const shouldValidateFuture = getStatusChangeRules()[status].allowUpdateToFutureDate === false;
  if (isDateString(timeStamp) && shouldValidateFuture) {
    return isFutureDate(timeStamp);
  }
  return false;
}

export function isOptionalStatus(status: AppointmentStatus): boolean {
  return Boolean(getStatusChangeRules()[status].isOptional);
}

export function isStartStatus(status: AppointmentStatus): boolean {
  return getStartStatuses().includes(status);
}

export function isUndoAllowed(status: AppointmentStatus): boolean {
  return validateAppointmentStatus(status) && getStatusChangeRules()[status].canUndo;
}

export function isRescheduleAllowed(status: AppointmentStatus): boolean {
  return isStartStatus(status);
}

export function isCancelAllowed(status: AppointmentStatus): boolean {
  const changeRules = getStatusChangeRules();
  return changeRules[status].validNextStatuses.includes(AppointmentStatus.Cancelled);
}

// map conditions in which the status time must be later than the previous status:
// Scheduled time must be later than requested time (start statuses)
// No-start statuses must be later than the previous and don't are affected by start statuses
export function isChronologyEnforced(
  currentStatus: AppointmentStatus,
  previousStatus: AppointmentStatus
): boolean {
  return (
    (isStartStatus(currentStatus) && isStartStatus(previousStatus)) ||
    (!isStartStatus(currentStatus) && !isStartStatus(previousStatus))
  );
}

export function getStartStatuses(): AppointmentStatus[] {
  const search = objectFilter(
    getStatusChangeRules(),
    key => getStatusChangeRules()[key].isStartState
  );

  return Object.keys(search) as AppointmentStatus[];
}

// Returns the status that appointments get automatically CREATED with. This used to
// always be "Scheduled", but now has the option of being "Requested" as well.
export function getCreationStatusFromSettings(settings: ISettings): AppointmentStatus {
  const validStatuses = getStartStatuses();
  const statusFromSetting = settings?.appointmentCreationStatus as AppointmentStatus;
  return validStatuses.includes(statusFromSetting)
    ? statusFromSetting
    : AppointmentStatus.Scheduled;
}

export function getReschedulableStatuses(): AppointmentStatus[] {
  const rules = getStatusChangeRules();
  const search = objectFilter(rules, key => isRescheduleAllowed(key));

  return Object.keys(search) as AppointmentStatus[];
}

export function validateAppointmentStatus(status: AppointmentStatus): boolean {
  return status && Object.keys(AppointmentStatus).includes(status);
}

export function isStatusTransitionAllowed(from: AppointmentStatus, to: AppointmentStatus): boolean {
  if (!(validateAppointmentStatus(from) && validateAppointmentStatus(to))) {
    return false;
  }
  return from === to || getStatusChangeRules()[from].validNextStatuses.includes(to);
}

export function validateStatusTimeline(inputStatusTimeline: StatusTimeline): string | null {
  if (typeof inputStatusTimeline !== 'object') {
    return 'statusTimeline must be a valid StatusTimeline object';
  }

  const inputStatusTimeLineKeys = Object.keys(inputStatusTimeline);

  // Ensure that only valid AppointmentStatus fields exist in the input
  if (!inputStatusTimeLineKeys.every(k => getAllStatuses().includes(k as AppointmentStatus))) {
    return `statusTimeline must only contain a subset of the following fields: ${getAllStatuses()}`;
  }

  // Ensure that all mandatory AppointmentStatus fields exist in the input
  if (
    !getNonOptionalStatuses().every(mandatoryStatus =>
      inputStatusTimeLineKeys.includes(mandatoryStatus)
    )
  ) {
    return `statusTimeline must at least contain the following fields: ${getNonOptionalStatuses()}`;
  }

  // Ensure that all the fields are valid Dates OR null
  for (const val of Object.values(inputStatusTimeline)) {
    const isEmptyDate = val?.toString().length === 0;
    const isInvalidDate = val && !(isDate(val) || isDateString(val));
    if (isEmptyDate || isInvalidDate) {
      return `statusTimeline: '${val}' is not a valid Date string`;
    }
  }

  // Ensure at least one start status exists
  const startStatuses = Object.keys(inputStatusTimeline).filter(
    (k: AppointmentStatus) => Boolean(inputStatusTimeline[k]) && getStartStatuses().includes(k)
  );

  if (startStatuses.length < 1 || startStatuses.length > getStartStatuses().length) {
    return `statusTimeline: Incorrect number of start Statuses: found ${startStatuses.length}`;
  }

  //////////////////////////////////////////////////////
  // Grab only the happy-path rules, and sort them, then
  // remove the clutter
  const rules = getStatusChangeRules();

  const statusHappyPath = _.map(
    _.reduce(
      rules,
      (result, value, key) => {
        if (value.isHappyPath) {
          result.push({ key, happyPathOrder: value.happyPathOrder });
        }
        return _.sortBy(result, 'happyPathOrder');
      },
      []
    ),
    'key'
  );

  const nonNullStatusHappyPath = statusHappyPath.filter(status => {
    return inputStatusTimeline[status] !== null;
  });

  function makeDate(date: Date | string | null | undefined) {
    return !date ? date : new Date(date);
  }

  // WARNING: This can be a little confusing. The gist is that we have to
  // enforce chronology on the "standard" path (ruleNames) AND the
  // standard path with all the NULL statuses removed! Hence the outer for-loop.
  //
  // This is all to accommodate the "InProgress" status correctly since
  // it can be set to NULL (i.e. it's optional).
  for (const path of [statusHappyPath, nonNullStatusHappyPath]) {
    for (let i = 1; i < path.length; i++) {
      const currentStatusName = path[i];
      const previousStatusName = path[i - 1];
      const currentStatusDateTime = makeDate(inputStatusTimeline[currentStatusName]);
      const previousStatusDateTime = makeDate(inputStatusTimeline[previousStatusName]);
      const hasCronEnforcement = isChronologyEnforced(currentStatusName, previousStatusName);
      const previousStatusIsOptional = isOptionalStatus(previousStatusName);

      // if currentStatus has a timestamp, then the previousStatus must have an earlier timestamp (not null)
      if (
        hasCronEnforcement &&
        currentStatusDateTime &&
        (!(previousStatusIsOptional || previousStatusDateTime) ||
          currentStatusDateTime < previousStatusDateTime)
      ) {
        return `statusTimeline: You cannot mark an Appointment ${currentStatusName} before ${previousStatusName}`;
      }
    }
  }

  // Do not allow future date changes on some statuses
  for (const [status, val] of Object.entries(inputStatusTimeline)) {
    if (isTimelineStatusChangeDateInvalid(status as AppointmentStatus, String(val))) {
      return `statusTimeline: '${status}' status timeline cannot be changed into a future date ${val}'`;
    }
  }

  return null;
}

export function getStatusesInHappyPathOrder(): string[] {
  return Object.entries(getStatusChangeRules())
    .sort(([aKey, aValue], [_, bValue]) => {
      return aValue.happyPathOrder > 0 ? aValue.happyPathOrder - bValue.happyPathOrder : 1;
    })
    .map(([key, _]) => {
      return key;
    });
}

export function getMostRecentStatusChange(statusTimeline: StatusTimeline): string {
  if (!isObject(statusTimeline)) {
    return '';
  }

  // TODO: What happens if an API call sets the timeline to the same value across the board for whatever reason?
  let statuses = Object.entries(statusTimeline)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => ({
      status: key,
      time: value
    }));
  statuses = sortBy(statuses, 'time').reverse();

  return statuses?.length > 0 ? statuses[0].status : '';
}

export function getMostRecentStatusChangeByHappyPath(statusTimeline: StatusTimeline): string {
  if (!isObject(statusTimeline)) {
    return '';
  }

  const statusesInReverseHappyPathOrder = getStatusesInHappyPathOrder().reverse();
  const statuses = Object.entries(statusTimeline)
    .filter(([_, value]) => Boolean(value))
    .map(([key]) => key);
  let lastStatus = '';

  statusesInReverseHappyPathOrder.some(status => {
    if (statuses.includes(status)) {
      lastStatus = status;
      return true;
    }
    return false;
  });

  return lastStatus;
}

// Returns true if status exists in the timeline and is set to a
// truthy value.
export function isStatusSetInStatusTimeline(
  status: AppointmentStatus,
  timeline: StatusTimeline
): boolean {
  const keys = Object.keys(timeline);
  return keys.includes(status) && Boolean(timeline[status]);
}

export function computeUndoStatus(
  rules: StatusChangeRules,
  status: AppointmentStatus,
  timeline: StatusTimeline
) {
  // Special case for "Completed" since it depends
  // on whether we "went through" the InProgress status.
  if (status === AppointmentStatus.Completed && timeline[AppointmentStatus.InProgress]) {
    return AppointmentStatus.InProgress;
  }

  return rules[status].undoStatus;
}
