import { DatabaseEntities, DatabaseOperations } from './base';
import * as UserLib from './user';
import {
  HttpStatus,
  ucWords,
  isAppointmentCancellation,
  isStatusChange,
  isStatusTimelineChange,
  IAppointment
} from './index';
import { canUserAccessWarehouse } from './user';

// A CrudGuard is a utility class to define how
// we validate if the logged user that is performing
// the request can or cannot do the requested endpoint
// request.
export type CrudGuard = {
  // A function signature that will be executed when the guard is called
  // It must receive a User, in this case will be the logged user performing the request
  // It can receive the request params and the request body for further inspection
  // It must return a boolean, true will tell the controller that the Guard allows the execution
  // and false will block the controller execution
  fn: (
    user: UserLib.IUser,
    params?: Record<string, string>,
    body?: Record<string, string>
  ) => boolean;
  statusCode?: HttpStatus;
  // This is a function that may receive a user to build an error message for the
  // Response data.
  message?: (user?: UserLib.IUser) => string;
  details?: (user?: UserLib.IUser) => string;
};

// In case of the operation doesn't need to be Guarded
// Add this Guard on it at the CanUser Map
const BypassCrudGuard: CrudGuard = {
  fn: _ => true,
  message: _ => ''
};

export function generateCrudErrorMessage(
  entity: DatabaseEntities,
  operation: DatabaseOperations
): string {
  const actionVerb = {
    Create: 'creating',
    Update: 'updating',
    Delete: 'deleting'
  };
  const action = actionVerb[operation] ? actionVerb[operation] : 'changing';
  return `User role does not support ${action} ${ucWords(entity)}s`;
}

/*
 * This is our security matrix, its types suggests that it must be and object
 * that contains as keys all our Entities, and for each entity we shall have another
 * object that has all the 4 CRUD operations as keys and for each key we shall have a valid
 * guard. The typing helps us NOT TO FORGET to map any new entity added to the system.
 * This Map is used on almost all Controller methods (endpoints) to be passed as an argument
 * To the decorator UserFromJWTWithGuard, so when any request comes to an endpoint, the decorator
 * will call the guard function defined in the fn key and prevent the execution of the controller code.
 */
type extraDatabaseOperations = 'UndoLastStatus';
type extraDatabaseEntities = 'Billing' | 'ReportSearch' | 'Carrier';

export const CanUser: Record<
  DatabaseEntities | extraDatabaseEntities,
  Partial<Record<DatabaseOperations | extraDatabaseOperations, CrudGuard>>
> = {
  [DatabaseEntities.Appointment]: {
    [DatabaseOperations.C]: {
      fn: UserLib.canUserCreateAppointment
    },
    [DatabaseOperations.U]: {
      fn: (user, _, body) => {
        if (isAppointmentCancellation(body)) {
          return UserLib.canUserCancelAppointment(user);
        }
        if (isStatusChange(body)) {
          return UserLib.canUserModifyApmtStatus(user);
        }
        if (isStatusTimelineChange(body)) {
          return UserLib.canUserModifyStatusTimeline(user);
        }
        return UserLib.canUserUpdateAppointment(user, body as Partial<IAppointment>);
      }
    },
    [DatabaseOperations.D]: {
      fn: user => UserLib.canUserDeleteAppointment(user)
    },
    UndoLastStatus: {
      fn: user => UserLib.canUserModifyApmtStatus(user)
    }
  },
  Billing: {
    [DatabaseOperations.C]: { fn: UserLib.canUserUpdateBilling },
    [DatabaseOperations.R]: { fn: UserLib.canUserReadBilling },
    [DatabaseOperations.U]: { fn: UserLib.canUserUpdateBilling },
    [DatabaseOperations.D]: { fn: () => false }
  },
  [DatabaseEntities.Company]: {},
  [DatabaseEntities.Dock]: {
    [DatabaseOperations.C]: {
      fn: UserLib.canUserCreateDocks
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: {
      fn: UserLib.canUserUpdateDocks
    },
    [DatabaseOperations.D]: {
      fn: UserLib.canUserDeleteDocks
    }
  },
  [DatabaseEntities.LoadType]: {
    [DatabaseOperations.C]: {
      fn: UserLib.canUserCreateLoadTypes
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: {
      fn: UserLib.canUserUpdateLoadTypes
    },
    [DatabaseOperations.D]: {
      fn: UserLib.canUserDeleteLoadTypes
    }
  },
  [DatabaseEntities.Org]: {
    [DatabaseOperations.C]: {
      fn: UserLib.canUserCreateOrg,
      message: () => "Can't join more than one Org",
      details: user => `User already belong to org ${user.orgId}`
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: {
      fn: (user, params) => UserLib.canUserUpdateOrg(user, params.id)
    },
    [DatabaseOperations.D]: {
      fn: (user, params) => UserLib.canUserDeleteOrg(user, params.id)
    }
  },
  [DatabaseEntities.OrgCarrierSettings]: {
    [DatabaseOperations.C]: {
      fn: user => UserLib.canUserChangeOrgCarrierSettings(user)
    },
    [DatabaseOperations.R]: {
      fn: user => UserLib.canUserReadOrgCarrierSettings(user)
    },
    [DatabaseOperations.U]: {
      fn: user => UserLib.canUserChangeOrgCarrierSettings(user)
    },
    [DatabaseOperations.D]: {
      fn: user => UserLib.canUserChangeOrgCarrierSettings(user)
    }
  },
  [DatabaseEntities.User]: {
    [DatabaseOperations.C]: {
      fn: user => UserLib.canUserCreateUser(user)
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: { fn: (user, params) => UserLib.canUserUpdateUser(user, params.id) },
    [DatabaseOperations.D]: {
      fn: (user, params) => UserLib.canUserDeleteUserById(user, params.id)
    }
  },
  [DatabaseEntities.Warehouse]: {
    [DatabaseOperations.C]: {
      fn: UserLib.canUserCreateWarehouse,
      message: user => {
        return UserLib.hasEmptyOrgId(user)
          ? 'You must belong to an Org before creating a warehouse'
          : generateCrudErrorMessage(DatabaseEntities.Warehouse, DatabaseOperations.C);
      }
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: {
      fn: UserLib.canUserUpdateWarehouse
    },
    [DatabaseOperations.D]: {
      fn: UserLib.canUserDeleteWarehouse
    }
  },
  WarehouseGroup: {
    [DatabaseOperations.C]: {
      fn: UserLib.canUserCreateWarehouseGroup
    },
    [DatabaseOperations.U]: {
      fn: UserLib.canUserUpdateWarehouseGroup
    },
    [DatabaseOperations.D]: {
      fn: UserLib.canUserDeleteWarehouseGroup
    }
  },
  ReportSearch: {
    [DatabaseOperations.C]: {
      fn: user => UserLib.canUserCreateReportSearch(user)
    },
    [DatabaseOperations.R]: {
      fn: (user, params) => UserLib.canUserReadOrgData(user, params.orgId)
    },
    [DatabaseOperations.U]: {
      fn: user => UserLib.canUserCreateReportSearch(user)
    },
    [DatabaseOperations.D]: {
      fn: user => UserLib.canUserDeleteReportSearch(user)
    }
  },
  Carrier: {
    [DatabaseOperations.C]: { fn: user => UserLib.canUserCreateCarrier(user) },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: { fn: user => UserLib.canUserUpdateCarrier(user) }
  },
  NotificationConfig: {
    [DatabaseOperations.C]: { fn: user => UserLib.canUserCreateNotificationConfig(user) },
    [DatabaseOperations.R]: { fn: user => UserLib.canUserReadNotificationConfig(user) },
    [DatabaseOperations.U]: { fn: user => UserLib.canUserUpdateNotificationConfig(user) }
  },
  Form: {},
  FormField: {},
  Field: {},
  Flow: {},
  CustomFormData: {
    [DatabaseOperations.C]: { fn: user => UserLib.canUserModifyCustomFormData(user) },
    [DatabaseOperations.U]: { fn: user => UserLib.canUserModifyCustomFormData(user) },
    [DatabaseOperations.D]: { fn: user => UserLib.canUserModifyCustomFormData(user) }
  },
  Trigger: {},
  AssetVisit: {},
  AssetVisitEvent: {},
  MessageThread: {},
  MessageThreadMessage: {},
  MessageThreadEvent: {},
  Spot: {
    [DatabaseOperations.C]: {
      fn: (user, _, body) =>
        UserLib.canUserCreateSpot(user) &&
        canUserAccessWarehouse(user, body?.warehouseId, user.orgId)
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: { fn: user => UserLib.canUserUpdateSpot(user) },
    [DatabaseOperations.D]: { fn: user => UserLib.canUserDeleteSpot(user) }
  },
  SpotArea: {
    [DatabaseOperations.C]: {
      fn: (user, _, body) =>
        UserLib.canUserCreateSpotArea(user) &&
        canUserAccessWarehouse(user, body?.warehouseId, user.orgId)
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: { fn: user => UserLib.canUserUpdateSpotArea(user) },
    [DatabaseOperations.D]: { fn: user => UserLib.canUserDeleteSpotArea(user) }
  },
  SpotAssignment: {
    [DatabaseOperations.C]: {
      fn: (user, params, body) =>
        UserLib.canUserCreateSpotAssignment(user) &&
        canUserAccessWarehouse(user, body?.warehouseId, user.orgId)
    },
    [DatabaseOperations.R]: BypassCrudGuard,
    [DatabaseOperations.U]: { fn: user => UserLib.canUserUpdateSpotAssignment(user) },
    [DatabaseOperations.D]: { fn: user => UserLib.canUserDeleteSpotAssignment(user) }
  }
};
