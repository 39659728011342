export enum FileUnits {
  B = 'B',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB'
}

export const allowedMimeTypes = [
  'image/',
  'text/',
  'application/pdf',
  'application/zip',
  'application/x-zip-compressed',
  'application/vnd.openxmlformats',
  'application/vnd.ms-excel'
];

export const deniedExtensions = ['.exe', '.bin', '.hex'];

export const storageFolder = 'storage';

export function fileKeyToDownloadUrl(s3BaseUrl: string, fileKey: string): string {
  const urlParts = fileKey.split('/');

  let decodedComponent;
  try {
    decodedComponent = decodeURIComponent(urlParts[urlParts.length - 1]);
  } catch {
    // If, for any reason, the component CANNOT be decoded, then it is likely an invalid file name to begin with,
    // and will not be downloadable anyway.
    decodedComponent = urlParts[urlParts.length - 1];
  }

  urlParts[urlParts.length - 1] = encodeURIComponent(decodedComponent);
  const sanitizedValue = urlParts.join('/');
  return `${s3BaseUrl}/${sanitizedValue}`;
}

export function urlToFileKey(s3BaseUrl: string, url: string): string {
  return url.replace(`${s3BaseUrl}/`, '');
}

export const convertBytes = (bytes: number, conversionUnit: FileUnits) => {
  const unitIndex = Object.values(FileUnits).indexOf(conversionUnit);
  return bytes / 1024 ** unitIndex;
};
