import { DateTime } from 'luxon';
import { isDate, isObject, isUUID } from 'class-validator';
import moment from 'moment-timezone';

import type { ICustomField } from './custom-field';
import { INovaEntity } from './base';
import { ITimeInterval, millisecondsIn, duration_hr } from './interval';
import { IHasOrgId } from './org';
import { AppointmentStatus, StatusTimeline } from './status';
import { utcNowAsDate } from './timezone';
import { absDiffDatesInMillis, Weekday } from './chronon';
import { GlobalLimits } from './limits';
import { breakWordsAtCaps, objPropExists, upperFirst } from './js-helpers';
import { IDock } from './dock';
import { isInternalUser, isWarehouseUser, IUser } from './user';
import { IWarehouse } from './warehouse';

export const MAX_RECURRING_APPOINTMENT_WEEKS = GlobalLimits.MAX_RECURRING_APPOINTMENT_WEEKS.value;

// These two constants are applied for carrier users only, warehouse users does not have such limitations
export const ETA_WINDOW_HOURS = 24;
export const ETA_SET_ALLOWED_STATUSES = [AppointmentStatus.Scheduled];

export enum AppointmentType {
  Standard = 'Standard',
  Reserve = 'Reserve'
}

export const DEFAULT_RESERVE_DURATION_MIN = 30;

export type RecurringPattern = {
  numWeeks: number;
  weekDays: Weekday[];
};

export type RescheduleMetadata = {
  from: Date;
  to: Date;
  by: string;
  timestamp: Date;
};

export enum RecurringExtraFieldsToCopyEnum {
  refNumber = 'refNumber',
  customFields = 'customFields',
  notes = 'notes',
  tags = 'tags'
}

export enum EtaCondition {
  OnTime = 'OnTime',
  Late = 'Late',
  Early = 'Early'
}

export enum AppointmentMetadataEnum {
  clonedFromId = 'clonedFromId'
}

export type AppointmentMetadata = {
  [AppointmentMetadataEnum.clonedFromId]?: string;
};

export function getEtaVerb(etaCondition: EtaCondition): string {
  return etaCondition === EtaCondition.Late ? 'running' : 'arriving';
}

export function getEtaCondition(start: Date, eta: Date, timezone: string): EtaCondition {
  let etaCondition = EtaCondition.OnTime;
  if (start && eta) {
    const appointmentStart = moment.tz(start, timezone).startOf('minute');
    const appointmentEta = moment.tz(eta, timezone).startOf('minute');
    const diff = moment.duration(appointmentStart.diff(appointmentEta)).asMinutes();
    etaCondition =
      diff === 0 ? EtaCondition.OnTime : diff < 0 ? EtaCondition.Late : EtaCondition.Early;
  }
  return etaCondition;
}

export function getReadableEtaStatus(etaCondition: EtaCondition): string {
  let etaStatus: string;
  if (etaCondition === EtaCondition.OnTime) {
    etaStatus = `${etaCondition}`;
  } else {
    etaStatus = `${getEtaVerb(etaCondition)} ${etaCondition}`;
  }

  return upperFirst(breakWordsAtCaps(etaStatus));
}

export const ETA_PREFIX_STRING = 'ETA:';

export interface IAppointment extends INovaEntity, IHasOrgId, ITimeInterval {
  eta?: Date;
  type: AppointmentType;
  status: AppointmentStatus;
  statusTimeline: StatusTimeline;
  userId: string;
  loadTypeId: string;
  dockId: string;
  orgId?: string;
  refNumber?: string;
  notes?: string;
  ccEmails: string[];
  customFields: ICustomField[];
  recurringParentId?: string;
  recurringPattern?: RecurringPattern;
  reschedules?: RescheduleMetadata[];
  confirmationNumber: number;
  muteNotifications?: boolean;
  isCheckedInByCarrier?: boolean;
  metadata?: AppointmentMetadata;

  dock?: IDock;
}

/**
 * Returns true if the eta is within the valid time window from start
 * @param start
 * @param eta
 */
export const ETACarrierValidations = {
  isEtaOnWindow,
  isStatusAllowedForETAUpdate
};

function isEtaOnWindow(start: Date, eta: Date): boolean {
  const etaStartDiffHours =
    absDiffDatesInMillis(new Date(start), new Date(eta)) / millisecondsIn.hour;
  return ETA_WINDOW_HOURS - etaStartDiffHours >= 0;
}

export function isWithin24HoursWindow(date: Date, timezone: string): boolean {
  // Get the current time in the specified timezone
  const now = DateTime.now().setZone(timezone).set({ millisecond: 0, second: 0 });

  let inputDate: DateTime;

  if (date instanceof Date) {
    inputDate = DateTime.fromJSDate(date).setZone(timezone);
  } else if (typeof date === 'string') {
    inputDate = DateTime.fromISO(date).setZone(timezone);
  } else if (DateTime.isDateTime(date)) {
    inputDate = (date as DateTime).setZone(timezone);
  }

  // Clear up the milliseconds and seconds
  inputDate = inputDate.set({ millisecond: 0, second: 0 });

  // Calculate the difference in hours between now and the input date
  const diff = now.diff(inputDate, 'hours').hours;

  // Check if the difference is within 24 hours
  return Math.abs(diff) <= 24;
}

function isStatusAllowedForETAUpdate(status: AppointmentStatus): boolean {
  return ETA_SET_ALLOWED_STATUSES.includes(status);
}

export function isAppointmentCancellation(body: Partial<IAppointment>): boolean {
  return Boolean(
    isObject(body) && isStatusChange(body) && body.status === AppointmentStatus.Cancelled
  );
}

export function isDockChanging(appt: IAppointment, dto: IAppointment): boolean {
  return isUUID(dto.dockId) && dto.dockId !== appt.dockId;
}

export function isStatusTimelineChange(body: Partial<IAppointment>): boolean {
  return Boolean(isObject(body) && Boolean(body.statusTimeline));
}

export function isStatusChange(body: Partial<IAppointment>): boolean {
  return Boolean(isObject(body) && Boolean(body.status));
}

export function isPastAppointment(appointment: IAppointment): boolean {
  let date = appointment.end;
  if (!isDate(appointment.end)) {
    // If provided end is not a date, it will not compare correctly.  We need to cast to a date
    date = new Date(appointment.end);
  }
  return Boolean(date) && date <= utcNowAsDate();
}

export function isRecurringAppointmentParent(appointment: IAppointment): boolean {
  return isRecurringAppointment(appointment) && appointment.recurringParentId === appointment.id;
}

export function isRecurringAppointmentChild(appointment: IAppointment): boolean {
  return isRecurringAppointment(appointment) && !isRecurringAppointmentParent(appointment);
}

export function isRecurringAppointment(appointment: IAppointment): boolean {
  return Boolean(appointment?.recurringParentId);
}

export function isReserve(appointment: IAppointment): boolean {
  return appointment.type === AppointmentType.Reserve;
}

export function isRequested(param: IAppointment | AppointmentStatus): boolean {
  if (!param) {
    throw Error('No parameter provided');
  }
  if (isObject(param)) {
    const appointment = param as IAppointment;
    if (!objPropExists(appointment, 'status')) {
      throw Error('Object provided does not have a "status" property');
    }
    return appointment.status === AppointmentStatus.Requested;
  }
  return param === AppointmentStatus.Requested;
}

export function isScheduled(param: IAppointment | AppointmentStatus): boolean {
  if (!param) {
    throw Error('No parameter provided');
  }
  if (isObject(param)) {
    const appointment = param as IAppointment;
    if (!objPropExists(appointment, 'status')) {
      throw Error('Object provided does not have a "status" property');
    }
    return appointment.status === AppointmentStatus.Scheduled;
  }
  return param === AppointmentStatus.Scheduled;
}

export function isInProgress(param: IAppointment | AppointmentStatus): boolean {
  if (!param) {
    throw Error('No parameter provided');
  }
  if (isObject(param)) {
    const appointment = param as IAppointment;
    if (!objPropExists(appointment, 'status')) {
      throw Error('Object provided does not have a "status" property');
    }
    return appointment.status === AppointmentStatus.InProgress;
  }
  return param === AppointmentStatus.InProgress;
}

export function getReschedulesCount(appointment: IAppointment): number {
  if (!appointment) {
    return 0;
  }
  // TODO: Remove this backwards compatibility with old reschedule tag system when it is no longer needed
  let tagCounter = 0;
  const rescheduledRegExp =
    /^Rescheduled - [0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/u;

  if (Array.isArray(appointment.tags)) {
    for (const tag of appointment.tags) {
      if (tag.match(rescheduledRegExp)) {
        tagCounter++;
      }
    }
  }

  return (appointment.reschedules ? appointment.reschedules.length : 0) + tagCounter;
}

export function getReschedulesString(reschedulesCount: number): string {
  if (reschedulesCount === 1) {
    return 'Rescheduled once';
  }
  if (reschedulesCount === 2) {
    return 'Rescheduled twice';
  }
  return `Rescheduled ${reschedulesCount} times`;
}

export function getReadableRecurringPatternParts(
  { numWeeks, weekDays }: { numWeeks: number; weekDays: string[] },
  utcStart: Date | string,
  timezone: string
) {
  const endDate = moment.tz(utcStart, timezone).add(numWeeks, 'weeks');
  return {
    weekDays: weekDays.map(day => day.slice(0, 3)).join(', '),
    endDate: endDate.format('MMM D, YYYY'),
    endTime: endDate.format('h:mma')
  };
}

export function getEtaTagString(condition: EtaCondition): string {
  return `${ETA_PREFIX_STRING}${condition}`;
}

export function getAppointmentStartStatus(
  appt: IAppointment,
  isLowerCase = false
): AppointmentStatus | string {
  let startStatus = (
    appt.statusTimeline[AppointmentStatus.Requested]
      ? AppointmentStatus.Requested
      : AppointmentStatus.Scheduled
  ) as string;
  if (isLowerCase) {
    startStatus = startStatus.toLowerCase();
  }
  return startStatus;
}

export function hasOnlyCCEmails(appt: Partial<IAppointment>): boolean {
  // The orgId and lastChangedBy are set by the crud guard
  const allowedKeys = ['ccEmails', 'lastChangedBy', 'orgId'];
  const keys = appt && Object.keys(appt);
  return Boolean(
    appt &&
      Array.isArray(appt.ccEmails) &&
      keys.length === 3 &&
      allowedKeys.every(c => keys.includes(c))
  );
}

export function findAppointmentWithNearestDate(appointments: IAppointment[]): IAppointment | null {
  if (appointments?.length > 0) {
    return appointments.reduce((previous, current) => {
      const now = moment();
      const prevDiff = Math.abs(moment(previous.start).diff(now));
      const currentDiff = Math.abs(moment(current.start).diff(now));
      return prevDiff < currentDiff ? previous : current;
    });
  }
  return null;
}

/*
 * When return is null, means that everything is fine, no rules were applied
 * When return is a string, means that a rule were enforced, and the string is the
 * Error Message
 */
export function validateCarrierLeadTimeRules(
  userJwt: IUser,
  appointment: IAppointment,
  dock: IDock,
  warehouse: IWarehouse
): string | null {
  if (!userJwt?.id) {
    return 'Appointment cannot be modified by unknown user';
  }

  if (isWarehouseUser(userJwt) || isInternalUser(userJwt)) {
    return null;
  }

  if (!dock?.id) {
    return 'Invalid dock object';
  }

  if (!warehouse?.id) {
    return 'Invalid warehouse object';
  }
  const now = DateTime.now().setZone(warehouse.timezone);

  // This means that we can have an *optional* custom min lead time for UPDATEs
  const leadTime = dock.minCarrierLeadTimeForUpdates_hr ?? 0;

  const appointmentStart = (
    isDate(appointment.start)
      ? DateTime.fromJSDate(appointment.start)
      : DateTime.fromISO(appointment.start as any)
  ).setZone(warehouse.timezone);

  // Explicit decision: Carriers can't modify appointments in the past
  if (appointmentStart < now) {
    return 'Cannot modify past appointment';
  }

  // Construct an interval that starts "NOW" and ends at the appointment's START
  const nowToApptInterval = { start: now.toJSDate(), end: appointment.start };
  const hoursTillApptStart = duration_hr(nowToApptInterval);
  if (hoursTillApptStart < leadTime) {
    return `Appointment cannot be modified within ${leadTime} hours of its start time`;
  }

  return null;
}
