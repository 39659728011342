import { DateTime } from 'luxon';
import {
  isArray,
  isDate,
  isDateString,
  isDefined,
  isEmail,
  isInt,
  isNumberString,
  isObject,
  isString
} from 'class-validator';

import type { IWarehouse } from './warehouse';
import { formatPhoneNumber, isPhoneValid, isTrulyEmpty } from './js-helpers';
import { isCarrierUser, IUser } from './user';
import { GlobalLimits } from './limits';
import { LuxonDateTimeFormats, formatDateTimeWithMilitarySupport } from './chronon';

export enum CustomFieldType {
  String = 'str',
  BigString = 'bigstr',
  Date = 'date',
  Bool = 'bool',
  Document = 'doc',
  MultiDocument = 'multidoc',
  Number = 'int',
  Email = 'email',
  Phone = 'phone',
  DropDown = 'dropdown',
  DropDownMultiSelect = 'dropdownmultiselect',
  ComboBox = 'combobox',
  Timestamp = 'timestamp',
  Action = 'action'
}

export const FIELDS_WITHOUT_PLACEHOLDER = [
  CustomFieldType.Document,
  CustomFieldType.MultiDocument,
  CustomFieldType.DropDown,
  CustomFieldType.DropDownMultiSelect,
  CustomFieldType.Date,
  CustomFieldType.Action
];

export const CUSTOM_FIELD_DROP_DOWN_OPTIONS_LIMIT = 1500;

export const customFieldLabels = {
  [CustomFieldType.String]: 'Text - Single Line',
  [CustomFieldType.BigString]: 'Text - Multiple Lines',
  [CustomFieldType.Date]: 'Date',
  [CustomFieldType.Bool]: 'True/False',
  [CustomFieldType.Document]: 'Single Document',
  [CustomFieldType.MultiDocument]: 'Multi Document',
  [CustomFieldType.Number]: 'Number',
  [CustomFieldType.Email]: 'E-mail',
  [CustomFieldType.Phone]: 'Phone',
  [CustomFieldType.DropDown]: 'Drop-down Single Select List',
  [CustomFieldType.DropDownMultiSelect]: 'Drop-down Multi Select List',
  [CustomFieldType.ComboBox]: 'Combo Box',
  [CustomFieldType.Timestamp]: 'Timestamp',
  [CustomFieldType.Action]: 'Feature Link'
};

export const customFieldDescriptions = {
  [CustomFieldType.String]: 'Open text field, single line',
  [CustomFieldType.BigString]: 'Open text field, multiple lines',
  [CustomFieldType.Date]: 'Format MM/DD/YYYY',
  [CustomFieldType.Bool]: 'Toggle Button',
  [CustomFieldType.Document]: 'Single file upload field',
  [CustomFieldType.MultiDocument]: 'Multiple file upload field',
  [CustomFieldType.Number]: ' Numeric input field',
  [CustomFieldType.Email]: 'Email address input field',
  [CustomFieldType.Phone]: 'Format +1(XXX) XXX-XXXX',
  [CustomFieldType.DropDown]: 'Select one option from a list',
  [CustomFieldType.DropDownMultiSelect]: 'Select multiple options from a list',
  [CustomFieldType.ComboBox]: 'Single option drop-down list + open text field',
  [CustomFieldType.Timestamp]: 'Date and time fields',
  [CustomFieldType.Action]: 'Link to the form features'
};

export const customFieldIcons = {
  [CustomFieldType.String]: 'format-letter-case',
  [CustomFieldType.BigString]: 'text-box-outline',
  [CustomFieldType.Date]: 'calendar-blank',
  [CustomFieldType.Bool]: 'toggle-switch-off-outline',
  [CustomFieldType.Document]: 'file-outline',
  [CustomFieldType.MultiDocument]: 'file-multiple-outline',
  [CustomFieldType.Number]: 'numeric',
  [CustomFieldType.Email]: 'at',
  [CustomFieldType.Phone]: 'phone-outline',
  [CustomFieldType.DropDown]: 'arrow-down-drop-circle-outline',
  [CustomFieldType.DropDownMultiSelect]: 'checkbox-multiple-outline',
  [CustomFieldType.ComboBox]: 'form-dropdown',
  [CustomFieldType.Timestamp]: 'clock-time-three-outline',
  [CustomFieldType.Action]: 'link-box-variant-outline'
};

export const MULTI_DOC_MAX_FILES_PER_APPOINTMENT =
  GlobalLimits.MULTI_DOC_MAX_FILES_PER_APPOINTMENT.value;

export function textCustomFieldLenghtIsOk(customField: ICustomField): boolean {
  return (
    (!isInt(customField.minLengthOrValue) ||
      String(customField.value).length >= customField.minLengthOrValue) &&
    (!isInt(customField.maxLengthOrValue) ||
      String(customField.value).length <= customField.maxLengthOrValue)
  );
}

export function numberCustomFieldRangeIsOk(customField: ICustomField): boolean {
  const value = Number(customField.value);
  return (
    (!isInt(customField.minLengthOrValue) || value >= customField.minLengthOrValue) &&
    (!isInt(customField.maxLengthOrValue) || value <= customField.maxLengthOrValue)
  );
}

export function isValidCustomField(customField: ICustomField): boolean {
  if (!(customField instanceof Object) || !(customField.type || customField.value)) {
    return false;
  }

  const value = customField.value;
  const isValidTextField = Boolean(isString(value)) && textCustomFieldLenghtIsOk(customField);

  return {
    [CustomFieldType.String]: isValidTextField,
    [CustomFieldType.BigString]: isValidTextField,
    [CustomFieldType.ComboBox]: isValidTextField,
    [CustomFieldType.Date]: Boolean(isDateString(value) || isDate(value)),
    [CustomFieldType.Bool]: value === true || value === false,
    [CustomFieldType.Document]: Boolean(isString(value)) && String(value).length > 0,
    [CustomFieldType.MultiDocument]:
      Array.isArray(value) &&
      value.length <= MULTI_DOC_MAX_FILES_PER_APPOINTMENT &&
      value.every(item => Boolean(isString(item)) && String(item).length > 0),
    [CustomFieldType.Number]: isInt(value) && numberCustomFieldRangeIsOk(customField),
    [CustomFieldType.Email]: Boolean(isEmail(value)),
    [CustomFieldType.Phone]: isPhoneValid(String(value)),
    [CustomFieldType.Timestamp]: isDateString(value),
    [CustomFieldType.DropDown]: Boolean(customField.dropDownValues?.includes(String(value))),
    [CustomFieldType.Action]: true,
    [CustomFieldType.DropDownMultiSelect]:
      Boolean((value as Array<string>)?.length === 0) ||
      Boolean(
        isArray(value) &&
          (value as Array<string>)?.every(i => customField.dropDownValues?.includes(i))
      )
  }[customField.type];
}

export interface ICustomField {
  label: string;
  type: CustomFieldType;
  name: string; // used as unique to index fields
  description?: string;
  order?: number;
  value?: string | boolean | number | string[];
  required?: boolean; // Used for api validation
  requiredForWarehouse?: boolean; // Used for frontend validation
  requiredForCarrier?: boolean; // Used for frontend validation
  dropDownValues?: string[]; // this will fill dropdown options
  hiddenFromCarrier?: boolean;
  placeholder?: string;
  minLengthOrValue?: number; // Number of chars if text type OR absolute number if number type
  maxLengthOrValue?: number;
}

export type testCustomField = Partial<ICustomField> & {
  typeLabel: string;
  options?: Record<string, any>;
  items?: any[];
};

// Formatting options keyed by custom field type go here
export type CustomFieldFormattingOpts = {
  [CustomFieldType.Document]?: {
    generateLink: boolean;
  };
  [CustomFieldType.MultiDocument]?: {
    generateLink: boolean;
  };
  [CustomFieldType.Timestamp]?: {
    timezone: string | null;
    twelveHoursFormat?: LuxonDateTimeFormats;
    formatAsMilitary: boolean;
    twentyFourHoursFormat?: LuxonDateTimeFormats;
  };
};

// TODO: This seems like it will be deprecated and will need a new version to match new UI designs
//  Update - maybe this will not be deprecated...
export function getCustomFieldFormattedValue(
  customField: ICustomField,
  opts: CustomFieldFormattingOpts = {}
) {
  if (!customField) {
    return null;
  }

  if (customField.type === CustomFieldType.Bool) {
    return customField.value ? 'Yes' : 'No';
  }

  const makeDocumentField = (value: string, options?: { generateLink: boolean }): string => {
    if (options?.generateLink) {
      return `<a href="${value}">${getDocumentNameFromUrl(value)}</a>`;
    }

    return getDocumentNameFromUrl(value);
  };

  if (customField.type === CustomFieldType.Document) {
    return makeDocumentField(customField.value as string, opts?.[CustomFieldType.Document]);
  }

  if (customField.type === CustomFieldType.MultiDocument && Array.isArray(customField.value)) {
    return customField.value
      .map((item: string) => makeDocumentField(item, opts?.[CustomFieldType.MultiDocument]))
      .join('<br />');
  }

  if (customField.type === CustomFieldType.DropDownMultiSelect) {
    if (isArray(customField.value)) {
      return (customField.value as Array<string>).join(', ');
    }
  }

  if (customField.type === CustomFieldType.Date) {
    // Custom fields are stored as ISO String Dates (YYYY-MM-DD)
    const value = String(customField.value);
    const dt = DateTime.fromISO(value);
    return dt.isValid ? dt.toFormat('MM/dd/yyyy') : value;
  }

  if (customField.type === CustomFieldType.Phone) {
    return formatPhoneNumber(String(customField.value));
  }

  if (customField.type === CustomFieldType.Timestamp) {
    if (DateTime.fromISO(String(customField.value)).isValid) {
      return formatDateTimeWithMilitarySupport(
        String(customField.value),
        opts?.[CustomFieldType.Timestamp]?.timezone,
        opts?.[CustomFieldType.Timestamp]?.twelveHoursFormat ||
          LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
        opts?.[CustomFieldType.Timestamp]?.formatAsMilitary || false,
        opts?.[CustomFieldType.Timestamp]?.twentyFourHoursFormat ||
          LuxonDateTimeFormats.MonthDayYearSlashedTime24
      );
    }
  }

  return customField.value;
}

export function getDocumentNameFromUrl(url: string, limit = 50): string {
  if (!url) {
    return;
  }
  const urlParts = url.split('/');
  let fileName = urlParts[urlParts.length - 1]
    ? decodeURIComponent(urlParts[urlParts.length - 1])
    : 'Download';
  if (fileName !== 'Download') {
    if (fileName.length > limit) {
      fileName = `${fileName.substring(0, limit - 3)}...`;
    }
  }
  return fileName;
}

export function getDefaultCustomFieldName(field: ICustomField) {
  if (field.type && field.label) {
    return `${field.type}${field.label}`;
  }

  return 'NO_FIELD_TYPE_AND_LABEL';
}

/*
 * This function updates the appointment custom fields according to the current warehouse rules for the custom field
 */
export function updateCustomFieldsFromWarehouse(
  existingCustomFields: ICustomField[],
  warehouse: IWarehouse,
  isCarrierView = false
) {
  if (isTrulyEmpty(existingCustomFields) || !Array.isArray(existingCustomFields)) {
    existingCustomFields = null;
  }

  const customFields =
    existingCustomFields ||
    warehouse?.customApptFieldsTemplate
      ?.filter(f => (isCarrierView ? !f.hiddenFromCarrier : true))
      .map(field => ({
        ...field,
        name: field.name ?? getDefaultCustomFieldName(field)
      })) ||
    [];

  // Get required information from warehouse template
  if (Array.isArray(customFields)) {
    return customFields.map(customField => {
      const fieldTemplate = warehouse?.customApptFieldsTemplate?.find(
        fieldTemplate =>
          fieldTemplate.label === customField.label && fieldTemplate.type === customField.type
      );
      if (isCarrierView) {
        customField.hiddenFromCarrier = fieldTemplate?.hiddenFromCarrier ?? false;
      }

      customField.required = getRequiredValue(fieldTemplate ?? customField, isCarrierView);

      return customField;
    });
  }

  return [];
}

export function getRequiredValue(field: ICustomField, isCarrierView = false) {
  if (!field) {
    return false;
  }
  if (isObject(field)) {
    if (isCarrierView) {
      if (Object.prototype.hasOwnProperty.call(field, 'requiredForCarrier')) {
        return Boolean(field.requiredForCarrier);
      }
    } else {
      if (Object.prototype.hasOwnProperty.call(field, 'requiredForWarehouse')) {
        return Boolean(field.requiredForWarehouse);
      }
    }
    return Boolean(field.required);
  }
  return false;
}

export function removeHiddenFromCarrierFields(customFields: ICustomField[]): ICustomField[] {
  return customFields ? customFields.filter(field => !field.hiddenFromCarrier) : customFields;
}

export function mergeCustomFieldsWithUserRestrictions(
  customFields: ICustomField[],
  customFieldsTemplate: ICustomField[],
  user: IUser
) {
  // Sucks, but order matters here to keep current functionality,
  // so I put this as an else if so you know these go together
  if (!customFields) {
    return customFieldsTemplate;
  } else if (!customFieldsTemplate) {
    return [];
  }

  return customFieldsTemplate.map(item => {
    const match = customFields.find(bItem => {
      const rejectUpdateForCarrier = item.hiddenFromCarrier && isCarrierUser(user);
      const foundItem = bItem.label === item.label && bItem.type === item.type;
      return foundItem && !rejectUpdateForCarrier;
    });
    if (match) {
      return {
        ...item,
        value: match.value
      };
    }
    return item;
  });
}

/**
 * "required" was deprecated, but it still exists for any customField set prior to it being deprecated.
 * Because it may still be on appointments WITHOUT requiredForX, we still need to check it, but only if
 * the appointment and/or warehouse do not have requiredForX values.
 */
export function hasRequiredCustomFieldsForUserType(customFields: ICustomField[], user: IUser) {
  if (customFields?.length) {
    if (isCarrierUser(user)) {
      return customFields.find((t: ICustomField) => t.requiredForCarrier ?? t.required)?.label;
    }
    return customFields.find((t: ICustomField) => t.requiredForWarehouse)?.label;
  }
}

export function castField(type: CustomFieldType, value: string) {
  let convertedValue: string | boolean | number | string[] | null;

  if (isDefined(value)) {
    try {
      switch (type) {
        case CustomFieldType.Bool:
          value = value?.toLowerCase();
          convertedValue = value !== 'true' && value !== 'false' ? null : Boolean(value === 'true');
          break;

        case CustomFieldType.Number:
          convertedValue = isNumberString(value) ? Number(value) : null;
          break;

        case CustomFieldType.MultiDocument:
          convertedValue = value ? JSON.parse(value) : null;
          break;

        case CustomFieldType.DropDownMultiSelect:
          convertedValue = value ? JSON.parse(value) : null;
          break;

        default:
          convertedValue = value;
      }
    } catch (e) {
      convertedValue = value;
    }
  }

  return convertedValue;
}
