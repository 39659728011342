import { GlobalLimits, IHasOrgId, IHasSchedule, IHasSettings, IWarehouse } from './';
import { INovaEntity, NovaId } from './base';
import { IOrg } from './org';

export enum LoadTypeDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}

export enum LoadTypeOperation {
  Live = 'Live',
  Drop = 'Drop',
  Other = 'Other'
}

export enum LoadTypeEquipmentType {
  DryVan = 'Dry Van',
  Flatbed = 'Flatbed',
  Reefer = 'Reefer',
  Other = 'Other'
}

export enum LoadTypeTransportationMode {
  FTL = 'FTL',
  PTL = 'PTL',
  Other = 'Other'
}

export const MAX_LOAD_TYPES_PER_WAREHOUSE = GlobalLimits.MAX_LOAD_TYPES_PER_WAREHOUSE.value;

export interface ILoadType extends INovaEntity, IHasOrgId, IHasSettings, IHasSchedule {
  name: string;
  duration_min: number;
  orgId: string;
  org?: IOrg;
  warehouseId?: NovaId;
  warehouse?: IWarehouse;
  direction: LoadTypeDirection;
  operation?: LoadTypeOperation;
  equipmentType?: LoadTypeEquipmentType;
  transportationMode?: LoadTypeTransportationMode;
  allowCarrierScheduling: boolean;
  description?: string;
}

export function isWarehouseLoadType(loadtype: ILoadType): boolean {
  return Boolean(loadtype.warehouseId);
}

export function getLoadtypeDirection(loadtype: ILoadType): string {
  if (!loadtype?.direction) {
    return '';
  }

  if (String(loadtype.direction).toUpperCase() === 'INBOUND') {
    return 'IN';
  }

  if (String(loadtype.direction).toUpperCase() === 'OUTBOUND') {
    return 'OUT';
  }

  return loadtype.direction;
}

// TODO: Write test for this
export function getLoadTypeName(
  loadType: Partial<ILoadType>,
  shouldIncludeDuration = true,
  shouldIncludeDirection = true
): string {
  let loadTypeName = loadType.name;
  if (shouldIncludeDuration) {
    loadTypeName += ` (${loadType.duration_min} min)`;
  }
  if (shouldIncludeDirection) {
    loadTypeName += ` - ${loadType.direction.toUpperCase()}`;
  }
  return loadTypeName;
}
