import moment, { type Moment } from 'moment-timezone';
import { isString } from 'class-validator';
import { DateTime } from 'luxon';

/**
 * @deprecated Use chronon's formatDateTime
 * */
export function renderUtcInTimezone(
  utcDate: Date,
  timezone: string,
  formatString = 'ddd, MMM Do YYYY @ h:mmA - z'
): string {
  // Timezone validation
  if (!moment.tz.zone(timezone)) {
    throw new Error(`Core: renderUtcInTimezone(): Invalid timezone ${timezone}`);
  }

  return moment(utcDate).tz(timezone).format(formatString);
}

/**
 * @deprecated Use chronon's formatDateTime
 * */
export function renderUtcInTimezoneMoment(utcDate: Date, timezone: string): string | Moment {
  // Timezone validation
  if (!isString(timezone) || !moment.tz.zone(timezone)) {
    throw new Error(`Core: renderUtcInTimezoneMoment(): Invalid timezone ${timezone}`);
  }

  return moment(utcDate).tz(timezone);
}

export function transformForUserFriendlyTimezone(
  formattedDateTime: string,
  timezone: string
): string {
  // For some timezones, moment returns the UTC offset instead of the timezone short name.
  // Ex: America/New_York returns EST/EDT, but America/Sao_Paulo returns -03, which may be confusing.
  // This trickery replaces UTC offset occurrences with the actual timezone name.
  // eslint-disable-next-line prefer-named-capture-group
  return formattedDateTime.replace(/(GMT)?[+-]\d{1,4}/u, timezone);
}

export function renderTimezoneInUtc(
  timestamp: string,
  timezone: string,
  formatString = null
): string {
  // Timezone validation
  if (!moment.tz.zone(timezone)) {
    throw new Error(`Core: renderTimezoneInUtc(): Invalid timezone ${timezone}`);
  }

  return moment.tz(timestamp, timezone).utc().format(formatString);
}

export function getFullDayIntervalFromDateInUtc(
  date: string,
  timezone: string
): Record<string, unknown> {
  // Timezone validation
  if (!moment.tz.zone(timezone)) {
    throw new Error(`Core: getFullDayIntervalFromDateInUtc(): Invalid timezone ${timezone}`);
  }
  return {
    start: moment.tz(date, timezone).startOf('day').utc().format(),
    end: moment.tz(date, timezone).endOf('day').utc().format()
  };
}

export function getFullDayIntervalFromDateInTimezone(
  date: string,
  timezone: string
): Record<string, unknown> {
  // Timezone validation
  if (!moment.tz.zone(timezone)) {
    throw new Error(`Core: getFullDayIntervalFromDateInTimezone(): Invalid timezone ${timezone}`);
  }
  return {
    start: moment.tz(date, timezone).startOf('day').format(),
    end: moment.tz(date, timezone).endOf('day').format()
  };
}

export function utcNow(): string {
  return moment().utc(false).format();
}

export function utcNowAsDate(): Date {
  return moment().utc(false).toDate();
}

export function isPastDate(dateString: string): boolean {
  const checkDate = new Date(dateString);
  checkDate.setUTCHours(23, 59, 59, 599);
  const currentDate = new Date();
  currentDate.setUTCHours(23, 59, 59, 599);
  return checkDate < currentDate;
}

export function isFutureDate(dateString: string): boolean {
  return DateTime.fromISO(dateString).diffNow('seconds').seconds > 0;
}

export type InputDate =
  | `${number}${number}${number}${number}-${number}${number}-${number}${number}`
  | Date;

type DiffBetweenDatesParams = {
  startDateStr: InputDate;
  endDateStr: InputDate;
  diffUnit?: string;
};

export function diffBetweenDates({
  startDateStr,
  endDateStr,
  diffUnit = 'week'
}: DiffBetweenDatesParams): number {
  const inputDateFormat = 'YYYY-MM-DD';
  const startDate = moment(startDateStr, inputDateFormat);
  const endDate = moment(endDateStr, inputDateFormat);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Math.abs(endDate.diff(startDate, diffUnit));
}

// TODO: Add test for this - not quite sure how...yet
export function getNowInTimezone(timezone?: string): DateTime {
  let now = DateTime.now();
  if (timezone) {
    now = now.setZone(timezone);
  }
  return now;
}
