import { isNumber, isString } from 'class-validator';

export const SELF_CHECKIN_RADIUS_LIMIT_MILES = 1;

export const GATE_MANAGEMENT_SALES_URL =
  'https://lp.opendock.com/gate-management?utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=opendock-gate-management-warehouse-opendock-q2-24&utm_content_email';

export const SELF_CHECKIN_EMAIL = 'carriercheckin@example.com';

export const SELF_CHECKIN_LEAD_TIME_MINUTES = 60 * 24;

export function milesToMeters(miles: number): number {
  if (isNumber(miles) || isString(miles)) {
    return Number(miles) * 1609.34;
  }
  throw new Error(`Invalid miles ${miles}`);
}
