import eachCons from 'each-cons';
import jsonStringify from 'json-stable-stringify';
import { maxDate, minDate } from './chronon';
import { chunk, isValidDate } from './js-helpers';

export const secondsIn = {
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60
};

export const millisecondsIn = {
  week: 604800 * 1000,
  day: 86400 * 1000,
  hour: 3600 * 1000,
  minute: 60 * 1000,
  second: 1000
};

export interface ITimeInterval {
  start: Date;
  end: Date;
}

export type ITimeIntervalList = ITimeInterval[];

/**
 * Converts two dates to an ITimeInterval
 * <pre>
 *   For now, disallow "negative" intervals, since they don't represent
 *   anything "real". We'll change the semantics in the future should the need arise.
 *   Note the extra "new Date()" is needed since 'start' and 'end' might be *strings*
 *   and not proper Date objects. Part of the weakness of Typescript's type system.
 * </pre>
 * @param start Start Date
 * @param end End date
 * @return ITimeInterval
 */
export function fromDates(start: Date | string, end: Date | string): ITimeInterval {
  const startObj = new Date(start);
  const endObj = new Date(end);

  if (start && end && startObj <= endObj) {
    return { start: startObj, end: endObj };
  }

  throw new Error(
    `Core: fromDates(): 'start' is after 'end', or invalid dates. start: ${start}, end: ${end}`
  );
}

export function clone({ start, end }: ITimeInterval) {
  return fromDates(start, end);
}

export function fromDateArray(tuple: [Date, Date]): ITimeInterval {
  return fromDates(...tuple);
}

export function toArray(ti: ITimeInterval) {
  const validateTIErrorMessage = validateInterval(ti);
  if (validateTIErrorMessage) {
    throw new Error(`Core: toArray(): ${validateTIErrorMessage}`);
  }
  return [ti.start, ti.end];
}

export function isEqual(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return jsonStringify(ti1) === jsonStringify(ti2);
}

export function addOffset_ms(ti: ITimeInterval, offset_ms: number): ITimeInterval {
  return fromDates(
    new Date(ti.start.getTime() + offset_ms),
    new Date(ti.end.getTime() + offset_ms)
  );
}

export function addOffset_s(ti: ITimeInterval, offset_s: number): ITimeInterval {
  return addOffset_ms(ti, offset_s * 1000);
}

export function addOffset_min(ti: ITimeInterval, offset_min: number): ITimeInterval {
  return addOffset_s(ti, offset_min * 60);
}

export function addOffset_hr(ti: ITimeInterval, offset_hr: number): ITimeInterval {
  return addOffset_min(ti, offset_hr * 60);
}

export function addOffset_day(ti: ITimeInterval, offset_day: number): ITimeInterval {
  return addOffset_hr(ti, offset_day * 24);
}

export function addOffset_week(ti: ITimeInterval, offset_week: number): ITimeInterval {
  return addOffset_day(ti, offset_week * 7);
}

export function duration_ms(ti: ITimeInterval): number {
  const validateTIErrorMessage = validateInterval(ti);
  if (validateTIErrorMessage) {
    throw new Error(`Core: duration: invalid interval ${validateTIErrorMessage}`);
  }

  return Number(new Date(ti.end)) - Number(new Date(ti.start));
}

// Explicit decision: returns 0 if the input is null/undefined
export function sumDurations_ms(tiArray: ITimeIntervalList): number {
  if (!tiArray) {
    return 0;
  }

  return tiArray.reduce((totalDuration, ti) => {
    return totalDuration + duration_ms(ti);
  }, 0);
}

export function duration_s(ti: ITimeInterval): number {
  return duration_ms(ti) / 1000;
}

export function duration_min(ti: ITimeInterval): number {
  return duration_s(ti) / 60;
}

export function duration_hr(ti: ITimeInterval): number {
  return duration_min(ti) / 60;
}

export function duration_day(ti: ITimeInterval): number {
  return duration_hr(ti) / 24;
}

export function duration_week(ti: ITimeInterval): number {
  return duration_day(ti) / 7;
}

// Since "Date" includes milliseconds, and we're an appointment-scheduling app,
// <= vs < is a non-issue. Let's stick to <=.
export function startsBefore(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return ti1.start <= ti2.start;
}

export function startsAfter(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return ti1.start >= ti2.start;
}

export function endsBefore(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return ti1.end <= ti2.end;
}

export function endsAfter(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return ti1.end >= ti2.end;
}

export function startsAfterEndOf(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return ti1.start >= ti2.end;
}

export function endsBeforeStartOf(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return ti1.end <= ti2.start;
}

export function doesntOverlap(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  if (!ti1 || !ti2) {
    return true;
  }

  return endsBeforeStartOf(ti1, ti2) || startsAfterEndOf(ti1, ti2);
}

export function overlaps(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return !doesntOverlap(ti1, ti2);
}

// Given ti1 is |-------|, and
// given ti2 is [-------------],
// check that [----|-------|----]
export function isSubInterval(ti1: ITimeInterval, ti2: ITimeInterval): boolean {
  return isEqual(intersectPair(ti1, ti2)[0], ti1);
}

// Checks that ti is a sub-interval of ANY of the input intervals
export function isAnySubInterval(ti: ITimeInterval, intervals: ITimeIntervalList): boolean {
  return intervals.some(interval => isSubInterval(ti, interval));
}

export function anyOverlaps(ti: ITimeInterval, intervals: ITimeIntervalList): boolean {
  return intervals.some(interval => overlaps(ti, interval));
}

export function overlapCount(ti: ITimeInterval, intervals: ITimeIntervalList): number {
  return intervals?.filter(interval => overlaps(ti, interval)).length ?? 0;
}

// Union or "bakes" two intervals together. There are three cases:
// A: [-------] |---|
// B: [---|-------|----]
// C: [--------|------]-------|
//
// Returns an array since Case A results in 2 intervals, not one
//
// ASSUMPTIONS: none
export function unionPair(ti1: ITimeInterval, ti2: ITimeInterval): ITimeInterval[] {
  if (!startsBefore(ti1, ti2)) {
    return unionPair(ti2, ti1);
  }

  // case A
  if (doesntOverlap(ti1, ti2)) {
    return [clone(ti1), clone(ti2)];
  }

  // case B
  if (endsBefore(ti2, ti1)) {
    return [clone(ti1)];
  }

  // case C
  return [fromDates(ti1.start, ti2.end)];
}

export function _unionLists(
  intervals1: ITimeIntervalList,
  intervals2: ITimeIntervalList
): ITimeIntervalList {
  if (!intervals1?.length) {
    return intervals2;
  }

  if (!intervals2?.length) {
    return intervals1;
  }

  const head1 = intervals1[0];
  const head2 = intervals2[0];

  // Reference semantics suck - make a copy
  intervals1 = intervals1.slice(1);
  intervals2 = intervals2.slice(1);

  let bolus = unionPair(head1, head2);

  [intervals1, intervals2].forEach(intervalList => {
    while (overlaps(bolus[bolus.length - 1], intervalList[0])) {
      const lastOfBolus = bolus.pop();
      const startOfIntervals = intervalList.shift();

      bolus = bolus.concat(unionPair(lastOfBolus, startOfIntervals));
    }
  });

  return bolus.concat(_unionLists(intervals1, intervals2));
}

// Given two input lists of ITimeIntervals, return the resultant
// union or "OR list".
// Intuitively, this is the "summation" of both lists, in time.
//
// ASSUMPTIONS:
// - Both input lists are sorted by start
// - None of the intervals in either list overlap
export function unionLists(
  intervals1: ITimeIntervalList,
  intervals2: ITimeIntervalList
): ITimeIntervalList {
  return sortByStart(_unionLists(intervals1, intervals2));
}

// Intersects two intervals together. There are three cases:
// A: [-------] |---|                 => null
// B: [---|-------|----]              => |-------|
// C: [--------|------]-------|       => |------]
//
// Returns an array to be consistent with unionPair()
//
// ASSUMPTIONS: none
export function intersectPair(ti1: ITimeInterval, ti2: ITimeInterval): ITimeInterval[] {
  if (!startsBefore(ti1, ti2)) {
    return intersectPair(ti2, ti1);
  }

  // case A
  if (doesntOverlap(ti1, ti2)) {
    return [];
  }

  // case B
  if (endsBefore(ti2, ti1)) {
    return [clone(ti2)];
  }

  // case C
  return [fromDates(ti2.start, ti1.end)];
}

// Given two input lists of ITimeIntervals, return the resultant
// intersection or "AND list".
// Intuitively, this computes where both lists overlap in time,
// returned as a list of ITimeIntervals
//
// ASSUMPTIONS:
// - Both input lists are sorted by start
// - None of the intervals in either list overlap
export function intersectLists(
  list1: ITimeIntervalList,
  list2: ITimeIntervalList
): ITimeIntervalList {
  if (!list1?.length || !list2?.length) {
    return [];
  }

  const result: ITimeInterval[] = [];
  let i = 0;
  let j = 0;

  while (i < list1.length && j < list2.length) {
    const interval1 = list1[i];
    const interval2 = list2[j];

    if (endsBeforeStartOf(interval1, interval2)) {
      i++;
    } else if (endsBeforeStartOf(interval2, interval1)) {
      j++;
    } else {
      result.push({
        start: maxDate(interval1.start, interval2.start),
        end: minDate(interval1.end, interval2.end)
      });

      if (endsBefore(interval1, interval2)) {
        i++;
      } else {
        j++;
      }
    }
  }

  return result;
}

export function noneOverlap(tiArray: ITimeIntervalList): boolean {
  // Vacuous case: none of the intervals overlap if there aren't any!
  if (tiArray.length === 0) {
    return true;
  }

  tiArray = sortByStart(tiArray);
  const pairArray = eachCons(tiArray, 2);

  return pairArray.every(pair => {
    return doesntOverlap(pair[0], pair[1]);
  });
}

export function someOverlap(tiArray: ITimeIntervalList): boolean {
  return !noneOverlap(tiArray);
}

export function sortByEdge(tiArray: ITimeIntervalList, edge: 'start' | 'end'): ITimeIntervalList {
  if (!['start', 'end'].includes(edge)) {
    throw new Error(`Core: sortByEdge(): invalid edge ${edge}`);
  }
  return tiArray.sort((ti1, ti2) => Number(ti1[edge]) - Number(ti2[edge]));
}

export function sortByStart(tiArray: ITimeIntervalList) {
  return sortByEdge(tiArray, 'start');
}

export function sortByEnd(tiArray: ITimeIntervalList) {
  return sortByEdge(tiArray, 'end');
}

export function sortByDuration(tiArray: ITimeIntervalList) {
  return tiArray.sort((ti1, ti2) => {
    return duration_ms(ti1) - duration_ms(ti2);
  });
}

// Given a background interval, "subtract" out a set of foreground intervals
// and return the difference as another set of intervals representing the "gaps"
// in between.
// This can be used to return "availability" represented as time gaps that are
// free in between scheduled appointments.
// Any gaps smaller or equal than durationFilter will be removed.
//
// ASSUMPTIONS:
// - The background "encompasses" all the intervals. I.e. it starts
// before any of them start, and ends after any of them end.
// - foregroundArray is sorted by start.
// - None of the intervals in foregroundArray overlap.
export function gaps(
  background: ITimeInterval,
  foregroundArray: ITimeIntervalList,
  durationFilter_ms = 1000
): ITimeIntervalList {
  const boundaries = [background.start, ...foregroundArray.flatMap(toArray), background.end];

  return chunk(boundaries, 2)
    .map(fromDateArray)
    .filter(ti => duration_ms(ti) > durationFilter_ms);
}

// Same as gaps() function above, but removes the assumption/precondition of the
// background "encompassing" the foregroundArray.
export function intersectedGaps(
  background: ITimeInterval,
  foregroundArray: ITimeIntervalList,
  durationFilter_ms = 1000
): ITimeIntervalList {
  return gaps(background, intersectLists([background], foregroundArray), durationFilter_ms);
}

// Takes the input interval list and "repeats" it n times, adding offset_s
// number of seconds each time.
//
// E.g. imagine tiArray represents a week-long schedule. If n == 4, and
// offset_s = <number of seconds in a week>, then what's returned is a list of
// intervals spanning 4 weeks, consisting of the first week's "pattern" repeated
// 4 times.
export function repeat(tiArray: ITimeIntervalList, n: number, offset_s: number) {
  if (!Number.isInteger(n) || !Number.isInteger(offset_s)) {
    throw new Error("Core: repeat(): 'n' and 'offset_s' must be integers");
  }

  let result = [];

  // Note we start at "0" offset so that the first
  // element is a deep-clone of the input array. Too bad
  // this is not Haskell (TS is reference vs value semantics).
  for (let i = 0; i < n; i++) {
    result = result.concat(tiArray.map(interval => addOffset_s(interval, i * offset_s)));
  }

  return mergeSmallGaps(result);
}

export function repeatWeekly(tiArray: ITimeIntervalList, n: number) {
  return repeat(tiArray, n, 604800 /* seconds in a week */);
}

// Returns: 'null' if interval is valid, otherwise a detailed error message.
export function validateInterval(i: ITimeInterval) {
  const keys = Object.keys(i);
  const validKeys = ['start', 'end'];

  for (const key of validKeys) {
    if (!keys.includes(key)) {
      return `Interval missing key: '${key}'`;
    }
  }

  if (!isValidDate(i.start)) {
    return `Interval.start is not a valid Date: '${i.start}'`;
  }

  if (!isValidDate(i.end)) {
    return `Interval.end is not a valid Date: '${i.end}'`;
  }

  if (i.start > i.end) {
    return `Interval 'start' (${i.start}) is later than 'end' (${i.end})`;
  }

  return null;
}

// Given an interval list, "stich together" or merge adjacent intervals that
// have a small enough gap between them into one interval, i.e.
// [-----------] |----------------|     becomes
// [------------------------------]
//
// ASSUMPTIONS:
// - List is sorted (by start time)
// - None of the intervals overlap
export function mergeSmallGaps(
  intervalList: ITimeIntervalList,
  gapThreshold_ms = millisecondsIn.minute
): ITimeIntervalList {
  if (!intervalList || intervalList.length < 2) {
    return intervalList;
  }

  const mergedList = [intervalList[0]]; // initialize with the first interval

  for (let i = 1; i < intervalList.length; i++) {
    const lastMerged = mergedList[mergedList.length - 1];
    const current = intervalList[i];

    const gap = current.start.getTime() - lastMerged.end.getTime();

    if (gap <= gapThreshold_ms) {
      // merge the current interval with the last one in the merged list
      const mergedTi = fromDates(lastMerged.start, current.end);
      mergedList[mergedList.length - 1] = mergedTi; // replace the last merged interval
    } else {
      // push the current interval onto the merged list
      mergedList.push(current);
    }
  }

  return mergedList;
}

// Given a sorted non-overlapping list of intervals, trim off a given
// amount of time from the "front" (left-to-right on the timeline), only
// considering the interval's time-spans, ignoring the "gaps" between them.
//
// This essentially "slices off" part of the list based on "consuming"
// the given amount of time from the intervals.
//
// So a list like the following:
//
// [----1 hr----]     [---30m---]   [----1 hr----]
//
// With a trim amount of 1hr and 15 min becomes:
//
//                         [15m-]   [----1 hr----]
//
export function trimFront(amount_ms: number, intervals: ITimeIntervalList) {
  const retval: ITimeIntervalList = [];

  for (const interval of intervals) {
    if (amount_ms <= 0) {
      retval.push(interval);
    } else if (duration_ms(interval) <= amount_ms) {
      amount_ms -= duration_ms(interval);
    } else {
      retval.push({
        start: new Date(interval.start.getTime() + amount_ms),
        end: interval.end
      });
      amount_ms = 0;
    }
  }

  return retval;
}

export function occursWithin(pointInTime: Date, interval: ITimeInterval): boolean {
  if (!pointInTime || !interval) {
    return false;
  }

  return pointInTime >= interval.start && pointInTime <= interval.end;
}
