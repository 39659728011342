import { isArray, isDate, isDefined } from 'class-validator';
import { DateTime, DateTimeOptions, DurationUnits } from 'luxon';

import { getReschedulesCount, IAppointment } from './appointment';
import { DatabaseEntities } from './base';
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from './chronon';
import { getDocumentNameFromUrl } from './custom-field';
import { CustomFormsFeatures } from './custom-forms';
import { sortBy } from './js-helpers';
import { ExportableFields } from './reporting';
import { EntitySettings, ISettings, makeEntitySettings } from './settings';
import { AppointmentStatus } from './status';
import { isCarrierUser, IUser } from './user';

export class ExportAppointment {
  constructor(
    public appointments: IAppointment[],
    public orgSettings: ISettings = {},
    public exportableFields: string[]
  ) {
    if (!isDefined(orgSettings)) {
      this.orgSettings = {};
    }
  }

  public convertAppointmentsToExcelData() {
    const refNumberDisplayName = String(
      this.orgSettings.referenceNumberDisplayName ??
        EntitySettings.Org.referenceNumberDisplayName.defaultValue
    );

    return sortBy(this.appointments || [], 'start').map(appointment => {
      const consolidatedSettings = makeEntitySettings(DatabaseEntities.Warehouse, {
        ...appointment.dock.warehouse,
        org: { settings: this.orgSettings }
      });

      return this.#makeRow(appointment, refNumberDisplayName, consolidatedSettings);
    });
  }

  #calculateAppointmentFields(
    appointment: Record<string, any>,
    settings: ISettings,
    timezone: string
  ) {
    let completedT: DateTime;
    let actualDuration: number;
    let onTimeMinutes: number;

    const militaryTimeEnabled = Boolean(
      typeof settings.enableMilitaryTime === 'boolean'
        ? settings.enableMilitaryTime
        : EntitySettings.Org.enableMilitaryTime.defaultValue
    );

    const arrivedT = appointment.statusTimeline[AppointmentStatus.Arrived]
      ? this.#getDateTimeObject(appointment.statusTimeline[AppointmentStatus.Arrived], {
          zone: timezone
        }).startOf('minute')
      : null;

    if (arrivedT && appointment.statusTimeline[AppointmentStatus.Completed]) {
      completedT = this.#getDateTimeObject(
        appointment.statusTimeline[AppointmentStatus.Completed],
        {
          zone: timezone
        }
      ).startOf('minute');
      actualDuration = completedT.diff(arrivedT, 'minutes').as('minutes');
    }

    if (arrivedT) {
      onTimeMinutes = this.#makeTimeDiff(appointment.start, arrivedT.toISO(), timezone, 'minutes');
    }

    return { completedT, arrivedT, onTimeMinutes, actualDuration, militaryTimeEnabled };
  }

  #makeRow(appointment: Record<string, any>, refNumberDisplayName: string, settings: ISettings) {
    const modifiedRow = {};

    const timezone = appointment.dock?.warehouse?.timezone || 'UTC';
    const { militaryTimeEnabled } = this.#calculateAppointmentFields(
      appointment,
      settings,
      timezone
    );

    this.#makeAppointmentDataCells(appointment, militaryTimeEnabled, timezone, modifiedRow);
    this.#makeStatusCells(appointment, settings, timezone, modifiedRow);
    this.#makeAppointmentEntitiesCells(appointment, modifiedRow);
    if (this.exportableFields.includes('REFERENCE_NUMBER')) {
      modifiedRow[refNumberDisplayName] = appointment.refNumber;
    }
    this.#makeCreateUpdateCells(appointment, timezone, militaryTimeEnabled, modifiedRow);
    this.#makeCarrierDataCells(appointment, modifiedRow);
    this.#makeTagsNotesCells(appointment, modifiedRow);

    if (this.exportableFields.includes('CUSTOM_FIELDS')) {
      this.#makeRowCustomFields(appointment, modifiedRow);
    }

    if (appointment.customFormData?.length) {
      this.#makeCustomFormsFields(appointment, modifiedRow);
    }

    return modifiedRow;
  }

  #makeAppointmentEntitiesCells(
    appointment: Record<string, any>,
    modifiedRow: Record<string, any>
  ) {
    this.#makeRowCell('WAREHOUSE', appointment.dock?.warehouse?.name, modifiedRow);
    this.#makeRowCell('DOCK', appointment.dock?.name, modifiedRow);
    this.#makeRowCell('LOAD_TYPE', appointment.loadType?.name, modifiedRow);
    this.#makeRowCell('LOAD_TYPE_DIRECTION', appointment.loadType?.direction, modifiedRow);
  }

  #makeTagsNotesCells(appointment: Record<string, any>, modifiedRow: Record<string, any>) {
    this.#makeRowCell('NOTES', appointment.notes, modifiedRow);
    this.#makeRowCell(
      'TAGS',
      !appointment.tags ? null : appointment.tags.map((t: string) => `#${t}`).join(', '),
      modifiedRow
    );
  }

  #makeCarrierDataCells(
    appointment: Record<string, any>,

    modifiedRow: Record<string, any>
  ) {
    this.#makeRowCell('CARRIER_COMPANY', appointment.user?.company?.name, modifiedRow);
    this.#makeRowCell(
      'CONTACT_NAME',
      appointment.user ? `${appointment.user.firstName} ${appointment.user.lastName}` : null,
      modifiedRow
    );
    this.#makeRowCell('CONTACT_EMAIL', appointment.user?.email, modifiedRow);
    this.#makeRowCell('CONTACT_PHONE', appointment.user?.phone, modifiedRow);
  }

  #makeAppointmentDataCells(
    appointment: Record<string, any>,
    militaryTimeEnabled: boolean,
    timezone: string,
    modifiedRow: Record<string, any>
  ) {
    this.#makeRowCell('CONFIRMATION_NUMBER', appointment.confirmationNumber, modifiedRow);
    this.#makeRowCell('APPOINTMENT_ID', appointment.id, modifiedRow);
    this.#makeRowCell(
      'APPOINTMENT_DATE',
      this.#getDateTimeObject(appointment.start, { zone: timezone }).toFormat(
        LuxonDateTimeFormats.MonthDayYearSlashed
      ),
      modifiedRow
    );
    this.#makeRowCell(
      'APPOINTMENT_TIME',
      formatDateTimeWithMilitarySupport(
        appointment.start,
        timezone,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        militaryTimeEnabled,
        LuxonDateTimeFormats.Extended24HrTime
      ),
      modifiedRow
    );
    this.#makeRowCell(
      'APPOINTMENT_TIMEZONE',
      formatDateTimeWithMilitarySupport(
        appointment.start,
        timezone,
        LuxonDateTimeFormats.AbbreviatedNamedOffset,
        militaryTimeEnabled,
        LuxonDateTimeFormats.AbbreviatedNamedOffset
      ),
      modifiedRow
    );
    this.#makeRowCell('APPOINTMENT_TYPE', appointment.type, modifiedRow);
  }

  #makeCreateUpdateCells(
    appointment: Record<string, any>,
    timezone: string,
    militaryTimeEnabled: boolean,
    modifiedRow: Record<string, any>
  ) {
    this.#makeRowCell(
      'RESCHEDULE_COUNT',
      getReschedulesCount(appointment as IAppointment).toFixed(0),
      modifiedRow
    );
    if (appointment.createdByUser?.id) {
      this.#makeRowCell(
        'CREATED_BY',
        `${appointment.createdByUser.firstName} ${appointment.createdByUser.lastName}`,
        modifiedRow
      );
      this.#makeRowCell(
        'CREATOR_TYPE',
        this.#makeAppointmentCreatorType(appointment.createdByUser),
        modifiedRow
      );
    }
    this.#makeRowCell(
      'CREATION_DATE',
      this.#getDateTimeObject(appointment.createDateTime, { zone: timezone }).toFormat(
        LuxonDateTimeFormats.MonthDayYearSlashed
      ),
      modifiedRow
    );

    if (appointment.lastChangedByUser?.id) {
      this.#makeRowCell(
        'LAST_UPDATED_BY',
        `${appointment.lastChangedByUser.firstName} ${appointment.lastChangedByUser.lastName}`,
        modifiedRow
      );
    }
    this.#makeRowCell(
      'LAST_UPDATE_DATE',
      this.#getDateTimeObject(appointment.lastChangedDateTime, { zone: timezone }).toFormat(
        LuxonDateTimeFormats.MonthDayYearSlashed
      ),
      modifiedRow
    );
    this.#makeRowCell(
      'LAST_UPDATE_TIME',
      formatDateTimeWithMilitarySupport(
        appointment.lastChangedDateTime,
        timezone,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        militaryTimeEnabled,
        LuxonDateTimeFormats.Extended24HrTime
      ),
      modifiedRow
    );
  }

  #makeStatusCells(
    appointment: Record<string, any>,
    settings: ISettings,
    timezone: string,
    modifiedRow: Record<string, any>
  ) {
    const { militaryTimeEnabled, onTimeMinutes, actualDuration, completedT, arrivedT } =
      this.#calculateAppointmentFields(appointment, settings, timezone);

    this.#makeRowCell(
      'SCHEDULED_DURATION',
      this.#makeTimeDiff(appointment.start, appointment.end, timezone, 'minutes').toFixed(0),
      modifiedRow
    );
    this.#makeRowCell('STATUS', appointment.status, modifiedRow);
    this.#makeRowCell(
      'ARRIVAL_DATE',
      arrivedT ? arrivedT.toFormat(LuxonDateTimeFormats.MonthDayYearSlashed) : '',
      modifiedRow
    );
    this.#makeRowCell(
      'ARRIVAL_TIME',
      arrivedT
        ? formatDateTimeWithMilitarySupport(
            arrivedT.toISO(),
            timezone,
            LuxonDateTimeFormats.Extended12HrTimeAMPMWithAbbreviatedNamedOffset,
            militaryTimeEnabled,
            LuxonDateTimeFormats.Extended24HrTimeWithAbbreviatedNamedOffset
          )
        : '',
      modifiedRow
    );
    this.#makeRowCell(
      'DEPARTURE_DATE',
      completedT ? completedT.toFormat(LuxonDateTimeFormats.MonthDayYearSlashed) : '',
      modifiedRow
    );
    this.#makeRowCell(
      'DEPARTURE_TIME',
      completedT
        ? formatDateTimeWithMilitarySupport(
            completedT.toISO(),
            timezone,
            LuxonDateTimeFormats.Extended12HrTimeAMPMWithAbbreviatedNamedOffset,
            militaryTimeEnabled,
            LuxonDateTimeFormats.Extended24HrTimeWithAbbreviatedNamedOffset
          )
        : '',
      modifiedRow
    );
    this.#makeRowCell('DWELL_TIME', actualDuration?.toFixed(0) || '', modifiedRow);
    this.#makeRowCell('ON_TIME', onTimeMinutes?.toFixed(0) || '', modifiedRow);

    this.#makeInProgressStatusCells(
      appointment,
      settings,
      timezone,
      militaryTimeEnabled,
      modifiedRow
    );
  }

  #makeInProgressStatusCells(
    appointment: Record<string, any>,
    settings: ISettings,
    timezone: string,
    militaryTimeEnabled: boolean,
    modifiedRow: Record<string, any>
  ) {
    let arrivedTimeStamp: string;
    let inProgressTimeStamp: string;
    let completedTimeStamp: string;

    // Since the default value is Completed, we can ignore these if there
    // are no settings
    if (settings.statusSucceedingArrived === AppointmentStatus.InProgress) {
      arrivedTimeStamp = appointment.statusTimeline[AppointmentStatus.Arrived];
      inProgressTimeStamp = appointment.statusTimeline[AppointmentStatus.InProgress];
      completedTimeStamp = appointment.statusTimeline[AppointmentStatus.Completed];
    }

    this.#makeRowCell(
      'IN_PROGRESS_DATE',
      inProgressTimeStamp
        ? this.#getDateTimeObject(inProgressTimeStamp).toFormat(
            LuxonDateTimeFormats.MonthDayYearSlashed
          )
        : '',
      modifiedRow
    );

    this.#makeRowCell(
      'IN_PROGRESS_TIME',
      inProgressTimeStamp
        ? formatDateTimeWithMilitarySupport(
            inProgressTimeStamp,
            timezone,
            LuxonDateTimeFormats.Extended12HrTimeAMPM,
            militaryTimeEnabled,
            LuxonDateTimeFormats.Extended24HrTime
          )
        : '',
      modifiedRow
    );

    let waitingTime = '';
    if (arrivedTimeStamp && inProgressTimeStamp) {
      waitingTime = this.#makeTimeDiff(
        arrivedTimeStamp,
        inProgressTimeStamp,
        timezone,
        'minutes'
      ).toFixed();
    }
    this.#makeRowCell('WAIT_TIME', waitingTime, modifiedRow);

    let processingTime = '';
    if (completedTimeStamp && inProgressTimeStamp) {
      processingTime = this.#makeTimeDiff(
        inProgressTimeStamp,
        completedTimeStamp,
        timezone,
        'minutes'
      ).toFixed();
    }
    this.#makeRowCell('PROCESSING_TIME', processingTime, modifiedRow);
  }

  #makeRowCell(key: string, value: string, row: Record<string, string>) {
    if (this.exportableFields.includes(key)) {
      row[ExportableFields[key]] = value;
    }
  }

  #makeAppointmentCreatorType(user: IUser) {
    if (!user) {
      return 'Unknown user';
    }
    return isCarrierUser(user) ? 'Carrier' : 'Warehouse';
  }

  #getDateTimeObject(date: string | Date, options?: DateTimeOptions): DateTime {
    if (isDate(date)) {
      date = date.toISOString();
    }
    return DateTime.fromISO(date, options);
  }

  #makeTimeDiff(
    start: string,
    end: string,
    timezone: string,
    unit: DurationUnits = 'milliseconds'
  ): number {
    const startDateTime = this.#getDateTimeObject(start, { zone: timezone }).startOf('minute');
    const endDateTime = this.#getDateTimeObject(end, { zone: timezone }).startOf('minute');
    const diff = endDateTime.diff(startDateTime, unit).toObject();
    console.log(start, end, diff, unit);
    return diff && diff[unit as string] ? diff[unit as string] : 0;
  }

  #makeCustomFormsFields(row: Record<string, any>, modifiedRow: Record<string, any>) {
    const customFormsRequested = this.exportableFields
      .filter(field => Object.keys(CustomFormsFeatures).includes(field))
      .map(field => CustomFormsFeatures[field]);
    if (!customFormsRequested.length) {
      return;
    }
    row.customFormData
      .filter((cfField: { feature: string }) => customFormsRequested.includes(cfField.feature))
      .forEach((cfField: { type: string; value: string; label: string | number }) => {
        if (cfField.type === 'multidoc') {
          return;
        }
        let v: any = cfField.value;
        if (cfField.type === 'doc') {
          if (cfField.value) {
            v = {
              t: 's',
              v: getDocumentNameFromUrl(cfField.value),
              l: {
                Target: cfField.value,
                Tooltip: 'Download File'
              } // Target is the URL, optionally specify Tooltip
            };
          }
        }
        if (cfField.type === 'bool') {
          v = cfField.value === 'true' ? 'Yes' : 'No';
        }
        modifiedRow[cfField.label] = v;
      });
  }

  #makeRowCustomFields(row: Record<string, string>, modifiedRow: Record<string, any>) {
    if (Array.isArray(row.customFields)) {
      const fieldCount = {};
      row.customFields.forEach(cField => {
        let v = cField.value;

        // Drop-down multi select store the value as array
        if (isArray(cField.value)) {
          v = cField.value.join(', ');
        }

        if (cField.type === 'int' && cField.value) {
          v = parseInt(cField.value, 10);
        }

        if (cField.type === 'doc') {
          if (cField.value) {
            v = {
              t: 's',
              v: getDocumentNameFromUrl(cField.value),
              l: {
                Target: cField.value,
                Tooltip: 'Download File'
              } // Target is the URL, optionally specify Tooltip
            };
          }
        }
        if (modifiedRow[cField.label]) {
          fieldCount[cField.label] = (fieldCount[cField.label] || 1) + 1;
          modifiedRow[`${cField.label} (${fieldCount[cField.label]})`] = v;
        } else {
          fieldCount[cField.label] = 1;
          modifiedRow[cField.label] = v;
        }
      });
    }
  }
}
