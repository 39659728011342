import moment from 'moment';
import { DateTime } from 'luxon';
import { isObject } from 'class-validator';

import { EntitySettings, type IHasSettings, type ISettings } from './settings';
import { DateTimeFormats } from './chronon';
import { isTrulyEmpty } from './js-helpers';
import type { INovaEntity } from './base';
import type { ICustomTag } from './custom-tag';
import type { IWarehouse } from './warehouse';
import type { IReportSearches } from './reporting';
import type { WrapperType } from './globals';

export interface IOrg extends INovaEntity, IHasSettings {
  name: string;
  reportSearches?: WrapperType<IReportSearches>;
  customTags?: WrapperType<ICustomTag>[];
  favoriteCarrierIds?: string[];
  expiresAt?: Date;
  isDataWallEnabled: boolean;
  externalBillingId: string;
}

export interface IHasOrgId {
  // Must be UUID
  // Note that this is *required* at the DB level.
  orgId?: string;
}

export interface IOrgCarrierSettings extends INovaEntity, IHasOrgId {
  userId: string; // Carrier ID
  emailCCs?: string[]; // List of emails to CC per appointment
  favoriteWarehouseIds?: string[]; // List of warehouse IDs
  favoriteWarehouses?: WrapperType<IWarehouse>[]; // List of warehouses derived from favoriteWarehouseIds field
}

export function formatExpiresAt(expiresAt: string | Date): string {
  return expiresAt ? moment(expiresAt).format(DateTimeFormats.ShortDate) : '-';
}

export function expiresAtDayDiff(expiresAt: string | Date): number | null {
  if (!expiresAt) {
    return null;
  }

  const getDiff = (dt: DateTime) => Math.ceil(dt.diff(DateTime.utc(), 'days').days);

  if (typeof expiresAt === 'string') {
    return getDiff(DateTime.fromISO(expiresAt, { zone: 'UTC' }));
  }

  if (typeof expiresAt === 'object') {
    return getDiff(DateTime.fromJSDate(expiresAt, { zone: 'UTC' }));
  }
}

export function isExpiresAtWarningVisible(expiresAt: string | Date): boolean {
  return expiresAt ? expiresAtDayDiff(expiresAt) <= WarnOrgExpirationDays : false;
}

export function getExpirationWarningText(expiresAt: string | Date): string {
  let expirationDiff = expiresAtDayDiff(expiresAt);

  if (expirationDiff === 0) {
    return 'This org will expire today';
  }
  if (expirationDiff < 0) {
    expirationDiff *= -1;
    return `This org expired ${expirationDiff} ${expirationDiff === 1 ? 'day' : 'days'} ago`;
  }
  if (expirationDiff > 0) {
    return `This org will expire in ${expirationDiff} ${expirationDiff === 1 ? 'day' : 'days'}`;
  }

  return '';
}

export const DefaultExpirationDaysForTrialAccounts = 15;

// Amount of days from org.expiresAt that a warning should be done
export const WarnOrgExpirationDays = 7;

export enum OrgType {
  Trial = 'Trial',
  Test = 'Test',
  Client = 'Client'
}

/*
 * This sets up the default settings for an Org,
 * if you pass an existing object, it will modify the object in-place
 * but if you pass an empty object, it will return a new one
 * we use this on the org controller, but also on the frontend which is a bad practice
 * because we will have inconsistent data.
 */
export function getDefaultOrgSettings(settings: ISettings, ignoreEmptyValues = false): ISettings {
  if (!isObject(settings)) {
    settings = {};
  }

  for (const [settingKey, setting] of Object.entries(EntitySettings.Org)) {
    if (
      setting.inputType !== 'string' &&
      typeof settings[settingKey] === 'undefined' &&
      setting.defaultValue !== null
    ) {
      if (ignoreEmptyValues && isTrulyEmpty(setting.defaultValue)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      settings[settingKey] =
        setting.valueType === 'number'
          ? parseInt(setting.defaultValue as string, 10)
          : setting.defaultValue;
    }
  }

  return settings;
}
