import { AppointmentStatus } from './status';
import { DateTime } from 'luxon';
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from './chronon';

export enum NotificationType {
  APPOINTMENT_UPDATED = 'appointment_updated',
  // TODO confirmed is confusing because of the requested status, look into changing to "scheduled"
  APPOINTMENT_CONFIRMED = 'appointment_confirmed',
  APPOINTMENT_NOSHOW = 'appointment_noshow',
  APPOINTMENT_RECURRING_SERIES_SCHEDULED = 'appointment_recurring_series_scheduled',
  APPOINTMENT_ARRIVED = 'appointment_arrived',
  APPOINTMENT_INPROGRESS = 'appointment_inprogress',
  APPOINTMENT_RESCHEDULED = 'appointment_rescheduled',
  APPOINTMENT_COMPLETED = 'appointment_completed',
  APPOINTMENT_CANCELLED = 'appointment_cancelled',
  APPOINTMENT_REQUESTED = 'appointment_requested',
  APPOINTMENT_REQUESTED_CONFIRMED = 'appointment_requested_confirmed',
  APPOINTMENT_TAGGED = 'appointment_tagged'
}

export interface INotification {
  baseTemplateFile: string;
  templateBlocks: string[];
  emailSubject: string;
  title: string;
  key: string;
  context: { [key: string]: any };
  enabledByDefault: boolean;
  UIRenderOptions?: { [key: string]: any }; // Options when building HTML for UI previews
}

export type INotificationCollection = {
  [k: string]: INotification;
};

export type INotificationContentBlockCollection = INotificationContentBlock[];

export enum AppointmentIdType {
  CONFIRMATION_NUMBER = 'confirmation_number',
  REFERENCE_NUMBER = 'reference_number'
}

export interface INotificationConfigSettings {
  displayableAppointmentFields?: AppointmentNotificationField[];
  appointmentIdType?: AppointmentIdType;
}

export enum ContentBlockStyleType {
  STANDARD = 'standard',
  INFO = 'info',
  ALERT = 'alert',
  HIDDEN = 'hidden'
}

// Order matters here. If the org has not specified fields & order for appointment notifications, this enum is used.
export enum AppointmentNotificationField {
  REFERENCE_NUMBER = 'reference_number',
  LOAD_TYPE = 'load_type',
  APPOINTMENT_CUSTOM_FIELDS = 'appointment_custom_fields',
  WAREHOUSE_INSTRUCTIONS = 'warehouse_instructions',
  CARRIER_CONTACT = 'carrier_contact',
  NOTES = 'notes',
  CONFIRMATION_NUMBER = 'confirmation_number',
  APPOINTMENT_ID = 'appointment_id',
  WAREHOUSE_PPE_REQUIREMENTS = 'warehouse_ppe_requirements',
  DOCK_INSTRUCTIONS = 'dock_instructions',
  DOCK_DOOR = 'dock_door',
  LOAD_TYPE_DESCRIPTION = 'load_type_description',
  AMENITIES_AVAILABLE = 'amenities_available'
}

export interface INotificationContentBlock {
  blockKey: NotificationContentBlockKey;
  content: string;
  isActive: boolean;
  styleType?: ContentBlockStyleType;
  meta?: {
    title?: string;
    description?: string;
  };
}

export enum NotificationContentBlockKey {
  APPOINTMENT_PARAGRAPH_ONE = 'appointment_paragraph_one'
}

export const NotificationContentBlocks: INotificationContentBlockCollection = [
  {
    blockKey: NotificationContentBlockKey.APPOINTMENT_PARAGRAPH_ONE,
    content: '',
    isActive: true,
    styleType: ContentBlockStyleType.STANDARD,
    meta: {
      title: 'Content Block',
      description:
        'This content is placed below the confirmation/reference number and above the appointment details.'
    }
  }
];

export const Notifications: INotificationCollection = {
  [NotificationType.APPOINTMENT_UPDATED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when-solo', 'where'],
    emailSubject: 'Appointment details have changed',
    key: NotificationType.APPOINTMENT_UPDATED,
    title: 'Appointment details have changed',
    enabledByDefault: true,
    context: {
      subTitle: 'Please check location, date, time and all details below.',
      appointmentAnchorLabel: 'View Appointment'
    }
  },
  [NotificationType.APPOINTMENT_CONFIRMED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'scheduled', 'where'],
    emailSubject: 'Appointment scheduled at $$orgName$$ - $$warehouseName$$',
    // TODO confirmed is confusing because of the requested status, look into changing to "scheduled"
    key: NotificationType.APPOINTMENT_CONFIRMED,
    title: 'Appointment scheduled',
    enabledByDefault: true,
    context: {
      subTitle: 'Please check location, date, time and all details below.',
      appointmentAnchorLabel: 'View Appointment'
    },
    UIRenderOptions: {
      defaultStartStatus: AppointmentStatus.Scheduled,
      ignoreOrgStartStatusSetting: true
    }
  },
  [NotificationType.APPOINTMENT_REQUESTED_CONFIRMED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'scheduled', 'where'],
    emailSubject: 'Appointment confirmed at $$orgName$$ - $$warehouseName$$',
    title: 'Appointment confirmed',
    key: NotificationType.APPOINTMENT_REQUESTED_CONFIRMED,
    enabledByDefault: true,
    context: {
      subTitle: 'Please check location, date, time and all details below.',
      appointmentAnchorLabel: 'View Appointment'
    },
    UIRenderOptions: {
      defaultStartStatus: AppointmentStatus.Requested,
      ignoreOrgStartStatusSetting: true,
      statusImagePrefix: 'requested_'
    }
  },
  [NotificationType.APPOINTMENT_REQUESTED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'requested', 'where'],
    emailSubject: 'Appointment requested at $$orgName$$ - $$warehouseName$$',
    title: 'Appointment requested',
    key: NotificationType.APPOINTMENT_REQUESTED,
    enabledByDefault: true,
    context: {
      subTitle: 'Please wait for warehouse confirmation.',
      appointmentAnchorLabel: 'View Appointment'
    },
    UIRenderOptions: {
      defaultStartStatus: AppointmentStatus.Requested,
      ignoreOrgStartStatusSetting: true,
      statusImagePrefix: 'requested_'
    }
  },
  [NotificationType.APPOINTMENT_NOSHOW]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'missed', 'where'],
    emailSubject: 'Missed appointment at $$orgName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_NOSHOW,
    title: 'Missed appointment',
    enabledByDefault: true,
    context: {
      subTitle: 'Carrier did not show for the appointment.',
      appointmentAnchorLabel: 'View Appointment'
    }
  },
  [NotificationType.APPOINTMENT_RECURRING_SERIES_SCHEDULED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'recurring', 'where'],
    emailSubject: 'Recurring appointment series has been created',
    key: NotificationType.APPOINTMENT_RECURRING_SERIES_SCHEDULED,
    title: 'Recurring appointment series created',
    enabledByDefault: true,
    context: {
      subTitle: 'Please check the details of the recurring appointment below.',
      appointmentAnchorLabel: 'View Original Appointment'
    }
  },
  [NotificationType.APPOINTMENT_ARRIVED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'arrived', 'where'],
    emailSubject: 'Truck arrived at $$orgName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_ARRIVED,
    title: 'Truck arrived at facility',
    enabledByDefault: true,
    context: {
      subTitle: 'Check the status and appointment details below.',
      appointmentAnchorLabel: 'View Appointment'
    }
  },
  [NotificationType.APPOINTMENT_INPROGRESS]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'inprogress', 'where'],
    emailSubject: 'Appointment is in progress at $$orgName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_INPROGRESS,
    title: 'Appointment is in progress at facility',
    enabledByDefault: true,
    context: {
      subTitle: 'Check the status and appointment details below.',
      appointmentAnchorLabel: 'View Appointment'
    },
    UIRenderOptions: {
      statusImagePrefix: 'inprogress_'
    }
  },
  [NotificationType.APPOINTMENT_RESCHEDULED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['rescheduled', 'where'],
    emailSubject: 'Appointment rescheduled at $$orgName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_RESCHEDULED,
    title: 'Appointment rescheduled',
    enabledByDefault: true,
    context: {
      subTitle: 'Check the status and appointment details below.',
      appointmentAnchorLabel: 'View Appointment'
    }
  },
  [NotificationType.APPOINTMENT_COMPLETED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'completed', 'where'],
    emailSubject: 'Appointment completed at $$orgName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_COMPLETED,
    title: 'Appointment completed',
    enabledByDefault: true,
    context: {
      subTitle: 'Check the status and appointment details below.',
      appointmentAnchorLabel: 'View Appointment'
    }
  },
  [NotificationType.APPOINTMENT_CANCELLED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'cancelled', 'where'],
    emailSubject: 'Appointment cancelled with $$orgName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_CANCELLED,
    title: 'Appointment cancelled',
    enabledByDefault: true,
    context: {
      subTitle: 'Please check all details and reason for cancellation.',
      appointmentAnchorLabel: 'View Appointment'
    }
  },
  [NotificationType.APPOINTMENT_TAGGED]: {
    baseTemplateFile: 'appointment.base.template.html',
    templateBlocks: ['when', 'tagged', 'where'],
    emailSubject: 'Appointment tagged as $$tagName$$ - $$warehouseName$$',
    key: NotificationType.APPOINTMENT_TAGGED,
    title: 'Appointment tagged',
    enabledByDefault: false,
    context: {
      subTitle: 'Please check the tag name and information below.',
      appointmentAnchorLabel: 'View Appointment'
    },
    UIRenderOptions: {
      description: 'Use Appointment Tags setting to enable tags individually'
    }
  }
};

/**
 * TODO: Spoke with Tyler Otto about this - we should probably have base mock data
 *  that specific notifications can extend
 *  So they can have a correct status timeline, status, and any other data
 *  that may be specific to the notification being viewed
 */
export const emailNotificationPayloadMock = Object.freeze({
  title: 'Appointment Email',
  body: null,
  orgName: 'Opendock Freight LLC',
  apptConfirmationNumber: '1234',
  apptId: '52d2c52e-13c5-4e9b-a446-b737a043f2c3',
  apptNotes: 'Appointment Notes',
  warehouseName: 'Opendock Warehouse',
  warehouseFacilityNumber: '1234',
  warehouseAddress: '2828 N Central Ave, Suite 800C',
  warehouseCity: 'Phoenix',
  warehouseState: 'AZ',
  warehouseZip: '85004',
  warehousePhone: '(480) 483-1199',
  warehouseEmail: 'support@opendock.com',
  warehouseGoogleMapsUrl: 'https://maps.google.com',
  appointmentDate: 'Wednesday, Feb 1, 2023 @ 11:00 AM (MST)',
  appointmentEnd: 'Wednesday, Feb 1, 2023 @ 12:00 AM (MST)',
  appointmentLastUpdated: 'Wednesday, Jan 15, 2023 @ 11:00 AM (MST)',
  appointmentLastUpdatedBy: 'Warehouse User',
  appointmentCustomFields:
    getAppointmentDetailHtmlBlock('Custom Field 1:', 'Custom Field 1 value') +
    getAppointmentDetailHtmlBlock('Custom Field 2:', 'Custom Field 2 value'),
  loadtypeDescription: getAppointmentDetailHtmlBlock(
    'Load Type Description',
    'This loadtype is for testing'
  ),
  truckArrivedAt: 'Wednesday, Feb 1, 2023 @ 10:35 AM (MST)',
  loadType: 'Frozen',
  refNumber: '---',
  instructions: 'Example warehouse instructions',
  dockInstructions: 'Example Dock Instructions',
  imagesPath: null,
  myAppointmentUrl: '#',
  carrierCompany: 'Carrier Company LLC',
  carrierName: 'John Doe Carrier',
  carrierEmail: 'carrier@example.com',
  recurringNumWeeks: 2,
  recurringWeekDays: 'Tuesday, Wednesday',
  settings: {},
  originalStart: 'Wednesday, Feb 2, 2023 @ 11:00 AM (MST)',
  customLogoPath: null,
  statusVerbiage: 'scheduled',
  tagName: 'Custom Tag',
  tagColor: '#2AAD76',
  tagTextColor: '#FFFFFF',
  apptDockDoor: '123',
  warehouseAmenities: 'Free Wi-Fi  •  Overnight Parking',
  warehousePpeRequirements: 'Glasses  •  Hard Hat  •  No Smoking',
  statusTimeline: {
    [AppointmentStatus.Requested]: DateTime.now().toISODate(),
    [AppointmentStatus.Scheduled]: DateTime.now().plus({ minute: 1 }).toISODate(),
    [AppointmentStatus.Arrived]: DateTime.now().plus({ minute: 2 }).toISODate(),
    [AppointmentStatus.Completed]: DateTime.now().plus({ minute: 3 }).toISODate(),
    [AppointmentStatus.NoShow]: DateTime.now().plus({ minute: 4 }).toISODate(),
    [AppointmentStatus.Cancelled]: DateTime.now().plus({ minute: 5 }).toISODate()
  }
});

export function getAppointmentDetailHtmlBlock(title: string, value: string): string | null {
  if (title?.length > 0 && value?.length > 0) {
    return `<div>
      <strong style="font-size: 16px">${title}</strong><br />
        <span style="font-size: 14px">${value}</span>
      </div>`;
  }
}

export function makeInjectedNotificationSubject(
  subject: string,
  orgName: string,
  warehouseName: string,
  apptConfirmationNumber: number,
  tagName: string
) {
  return `${String(subject)
    .replace('$$orgName$$', orgName)
    .replace('$$tagName$$', tagName)
    .replace('$$warehouseName$$', warehouseName)} - #${apptConfirmationNumber}`;
}
